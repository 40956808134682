import React, { useState, useEffect, useContext } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "services/api";
import { __ } from "services/Translator";
import { Modal, Button, Alert } from "react-bootstrap";

const InactivityChecker = (props) => {
  const { updateUserAuthStatus } = useContext(AppContext);

  const logout = async () => {
    await auth.logout();
    updateUserAuthStatus();
    setRemainingTime(null);
  };

  const [initialSettings, setInitialSettings] = useState({});
  const [remainingTime, setRemainingTime] = useState(null);

  const getInitialSettings = async () => {
    var response = await api.get("/initial-settings");
    if (response.status === 200) {
      setInitialSettings(response.data);
    }
  };

  useEffect(() => {
    getInitialSettings();
    window.setInterval(() => {
      getInitialSettings();
    }, 30000);
    updateLastActivityTime();
    checkLatestActivityTime();
    ["mousemove", "keyup", "click", "scroll"].forEach(function (e) {
      window.addEventListener(e, updateLastActivityTime, false);
    });
    return () => {
      ["mousemove", "keyup", "click", "scroll"].forEach(function (e) {
        window.removeEventListener(e, updateLastActivityTime, false);
      });
    };
  }, []);

  useEffect(() => {
    var timeout = remainingTime <= 30 ? 1000 : 5000;
    var interval = window.setInterval(() => {
      checkLatestActivityTime();
    }, timeout);
    return () => window.clearInterval(interval);
  }, [initialSettings, remainingTime]);

  const updateLastActivityTime = () => {
    ls.set("lastActivityTime", new Date());
    setRemainingTime(null);
  };

  const checkLatestActivityTime = () => {
    try {
      if (initialSettings.frontend_session_timeout && initialSettings.frontend_inactive_time) {
        var latestActivity = ls.get("lastActivityTime");
        var diff = parseInt((new Date().getTime() - new Date(latestActivity).getTime()) / 1000);
        var remainingTime = parseInt(initialSettings.frontend_inactive_time * 60) - diff;
        if (remainingTime < 0) remainingTime = 0;
        setRemainingTime(remainingTime);
        if (remainingTime === 0) {
          logout();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <>
      <Modal show={remainingTime !== null && remainingTime <= 30}>
        <Modal.Body className="login_modal security_popup">
          <div className="modal_headline">{__("Session timeout warning!!")}</div>
          <div className="form_area">
            <div className="input_item">
              <div className="name">
                {__("You have been inactive for") +
                  " " +
                  (parseInt(initialSettings.frontend_inactive_time * 60) - remainingTime) +
                  " " +
                  __("seconds") +
                  ". " +
                  __("Your session is about to expire in") +
                  " " +
                  remainingTime +
                  " " +
                  __("seconds") +
                  ". " +
                  __("Please remain active to continue session.")}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InactivityChecker;
