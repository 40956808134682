import { createSlice } from "@reduxjs/toolkit";

export const userAuth = createSlice({
  name: "userAuth",
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsAuthenticated } = userAuth.actions;

export default userAuth.reducer;
