// import { useEffect } from "react";
import { __ } from "services/Translator";
export default function VipLevelText(props) {
  // const __ = (text) => text;
  var identityText = "";
  switch (props.identity) {
    case 0:
    case 1:
      identityText = __("Beginner");
      break;
    case 2:
      identityText = __("Bronze");
      break;
    case 3:
      identityText = __("Silver");
      break;
    case 4:
      identityText = __("Gold");
      break;
    case 5:
      identityText = __("Platinum");
      break;
    case 6:
      identityText = __("Diamond");
      break;
    default:
      identityText = __("Calculating");
  }
  return identityText;
}

export function VipIcon(props) {
  var vipLevelClass = "vip_level_icon ";
  var identity = props.identity;
  // identity = 5;
  if (props.noFadedIcons) {
    switch (identity) {
      case 0:
      case 1:
        return <i className="far fa-star beginner"></i>;
        break;
      case 2:
        return <i className="far fa-star bronze"></i>;
        break;
      case 3:
        return <i className="fas fa-star silver"></i>;
        break;
      case 4:
        return <i className="fas fa-star gold"></i>;
        break;
      case 5:
        return <i className="fal fa-stars platinum"></i>;
        break;
      case 6:
        return <i className="fal fa-star-christmas diamond"></i>;
        break;
      default:
        break;
    }
  } else {
    switch (identity) {
      case 0:
      case 1:
        return <i className="far fa-star beginner"></i>;
        break;
      case 2:
        return <i className="far fa-star bronze"></i>;
        break;
      case 3:
        return <i className="fad fa-star silver"></i>;
        break;
      case 4:
        return <i className="fad fa-star gold"></i>;
        break;
      case 5:
        return <i className="fad fa-stars platinum"></i>;
        break;
      case 6:
        return <i className="fad fa-star-christmas diamond"></i>;
        break;
      default:
        break;
    }
  }
}

export function AngleRight(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="9.5 7.9 5 8.35"
        width={(props.width ? props.width : "20") + "px"}
        height={(props.height ? props.width : "20") + "px"}
        className={props.className ? props.className : ""}
      >
        <path
          d="M10.25 16.25C10.1493 16.2466 10.0503 16.2227 9.95921 16.1797C9.86807 16.1367 9.78668 16.0756 9.72001 16C9.57956 15.8594 9.50067 15.6688 9.50067 15.47C9.50067 15.2713 9.57956 15.0806 9.72001 14.94L12.72 11.94L9.72001 8.94002C9.66069 8.79601 9.64767 8.63711 9.68277 8.48536C9.71786 8.33361 9.79933 8.19656 9.91586 8.09322C10.0324 7.98988 10.1782 7.92538 10.3331 7.90868C10.4879 7.89198 10.6441 7.92391 10.78 8.00002L14.28 11.5C14.4205 11.6407 14.4994 11.8313 14.4994 12.03C14.4994 12.2288 14.4205 12.4194 14.28 12.56L10.78 16C10.7133 16.0756 10.6319 16.1367 10.5408 16.1797C10.4497 16.2227 10.3507 16.2466 10.25 16.25Z"
          fill={props.fillColor ? props.fillColor : "#000000"}
        />
      </svg>
    </>
  );
}

export function AngleLeft(props) {
  return (
    <>
      <svg
        viewBox="9.5 7.9 5 8.35"
        width={(props.width ? props.width : "20") + "px"}
        height={(props.height ? props.width : "20") + "px"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className ? props.className : ""}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M13.75 16.25C13.6515 16.2505 13.5538 16.2313 13.4628 16.1935C13.3718 16.1557 13.2893 16.1001 13.22 16.03L9.72001 12.53C9.57956 12.3894 9.50067 12.1988 9.50067 12C9.50067 11.8013 9.57956 11.6107 9.72001 11.47L13.22 8.00003C13.361 7.90864 13.5285 7.86722 13.6958 7.88241C13.8631 7.89759 14.0205 7.96851 14.1427 8.08379C14.2649 8.19907 14.3448 8.35203 14.3697 8.51817C14.3946 8.68431 14.363 8.85399 14.28 9.00003L11.28 12L14.28 15C14.4205 15.1407 14.4994 15.3313 14.4994 15.53C14.4994 15.7288 14.4205 15.9194 14.28 16.06C14.1353 16.1907 13.9448 16.259 13.75 16.25Z"
            fill={props.fillColor ? props.fillColor : "#000000"}
          ></path>{" "}
        </g>
      </svg>
    </>
  );
}

export function GiftBox(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        fill={props.fillColor ? props.fillColor : "#000000"}
        version="1.1"
        id="Capa_1"
        space="preserve"
        viewBox="1.38 0 18 20.76"
        width={(props.width ? props.width : "20") + "px"}
        height={(props.height ? props.width : "20") + "px"}
        className={props.className ? props.className : ""}
      >
        <g>
          <g>
            <path d="M1.88,18.76c0,1.101,0.9,2,2,2h4.5V10.281h-6.5V18.76z" />
            <path d="M12.38,20.76h4.5c1.1,0,2-0.899,2-2v-8.479h-6.5V20.76z" />
            <path d="M17.255,4.26H14c0.103-0.012,0.2-0.024,0.307-0.036c2.113-0.237,3.798-1.001,3.798-2.971c0-0.657-0.949-1.532-2.567-1.167    c-1.607,0.363-3.683,2.152-4.256,3.078c-0.147-0.354-0.495-0.6-0.901-0.6c-0.405,0-0.753,0.246-0.9,0.598    C8.906,2.238,6.83,0.448,5.223,0.086c-1.618-0.365-2.568,0.51-2.568,1.167c0,1.97,1.685,2.733,3.798,2.971    C6.559,4.236,6.658,4.248,6.76,4.26H3.506c-1.169,0-2.126,0.956-2.126,2.125v2.896h7V4.219h1.5V20.76h1V4.219h1.5v5.062h7V6.385    C19.38,5.216,18.423,4.26,17.255,4.26z M6.73,3.906c-1.565-0.176-2.813-0.74-2.813-2.199c0-0.487,0.704-1.135,1.902-0.864    c1.392,0.314,3.268,2.08,3.268,2.567C9.088,3.895,8.148,4.066,6.73,3.906z M11.673,3.409c0-0.487,1.876-2.253,3.268-2.567    c1.197-0.271,1.901,0.377,1.901,0.864c0,1.459-1.247,2.023-2.813,2.199C12.612,4.066,11.673,3.895,11.673,3.409z" />
          </g>
        </g>
      </svg>
    </>
  );
}

export function HeartIcon(props) {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        width={(props.width ? props.width : "20") + "px"}
        height={(props.height ? props.width : "20") + "px"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className ? props.className : ""}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
            fill={props.fillColor ? props.fillColor : "#000000"}
          ></path>{" "}
        </g>
      </svg>
    </>
  );
}
