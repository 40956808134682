import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import CategoryGames from "elements/GameElements/CategoryGames";
import GameItem from "elements/GameElements/GameItem";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function FavoriteGames(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/favorites", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <PageHeadline page_name={__("My Games")} />

      <div className="page_content_area">
        <div className="d-block ">
          {!!results.length ? (
            <>
              <div className="gamesLists  favorite_games_area">
                {results.map((game, index) => (
                  <GameItem key={game.id} game={game} />
                ))}
              </div>

              <br />
              <br />
              <Pagination data={pagination} onPageChange={onPageChange} />
            </>
          ) : (
            <NoRecordsFound
              content={__("Looks like you did not add any favorite games.")}
            />
          )}
        </div>
      </div>
    </>
  );
}
