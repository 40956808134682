import React, { useState, useEffect, useContext } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import { Modal } from "react-bootstrap";
import { isMobile } from "services/isMobile";
import { Link } from "react-router-dom";
import UserLeftArea from "elements/dashboardElements/UserLeftArea";
import PageHeadline from "../PageHeadline";
import AppContext from "contexts/AppContext";
import { useSelector } from "react-redux";

export default function Withdraw(props) {
  const userDetails = props.userDetails;
  const userBalance = useSelector((state) => state.userBalance);

  const [paymentSystem, setPaymentSystem] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalWallet, setWithdrawalWallet] = useState("");
  const [withdrawalEmail, setWithdrawalEmail] = useState("");
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [withdrawalConfirmationModal, setWithdrawalConfirmationModal] = useState(false);
  const [withdrawalError, setWithdrawalError] = useState(null);

  const [withdrawalOption, setWithdrawalOption] = useState("");

  const { getUserBalance } = useContext(AppContext);

  const getWithdrawalMethods = async () => {
    // if (withdrawalAmount) {
    props.setSpinner(true);
    var response = await api.post("/withdraw/methods", {
      amount: withdrawalAmount,
      type: withdrawalOption,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setWithdrawalMethods(response.data.methods);
      if (!response.data.methods.filter((method) => method.method_id === withdrawalMethod).length) {
        setWithdrawalMethod(null);
      }
      setWithdrawalDetails(response.data.details);
    }
    // }
  };

  const isWithdrawPossible = async () => {
    if (!withdrawalMethod) {
      setWithdrawalError(__("Please select a withdrwal method"));
    } else if (!withdrawalWallet && isWalletAddressRequired()) {
      setWithdrawalError(__("Please enter your wallet address"));
    } else {
      var response = await api.post("/is-withdraw-possible", {
        method: withdrawalMethod,
        accNumber: withdrawalWallet,
      });
      if (response.status === 200) {
        setWithdrawalError(null);
      } else {
        setWithdrawalError(response.data.message);
      }
    }
  };

  const isWithdrawPossibleDirect = async () => {
    if (!withdrawalMethod) {
      setWithdrawalError(__("Please select a withdrwal method"));
    } else {
      // var response = await api.post("/is-withdraw-possible", {
      //   method: withdrawalMethod,
      //   accNumber: withdrawalWallet,
      // });
      // if (response.status === 200) {
      //   setWithdrawalError(null);
      // } else {
      //   setWithdrawalError(response.data.message);
      // }
      setWithdrawalConfirmationModal(true);
    }
  };

  const showWithdrwalConfirmationDirect = (event) => {
    event.preventDefault();
    setWithdrawalError("");
    isWithdrawPossibleDirect();
  };

  const showWithdrwalConfirmation = (event) => {
    event.preventDefault();
    isWithdrawPossible();
    setWithdrawalConfirmationModal(true);
  };
  const closeWithdrwalConfirmation = () => {
    setWithdrawalConfirmationModal(false);
  };

  const submitWithdraw = async () => {
    if (withdrawalError) {
      return;
    }
    props.setSpinner(true);
    var response = await api.post("/withdraw", {
      amount: withdrawalAmount,
      method: withdrawalMethod,
      accNumber: withdrawalWallet,
      email: withdrawalEmail,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        props.showAlert("Success!!", response.data.message);
        setWithdrawalAmount("");
        setWithdrawalMethod(null);
        setWithdrawalWallet("");
        getWithdrawalMethods();
        closeWithdrwalConfirmation();
        getUserBalance();
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const isWalletAddressRequired = (method) => {
    var required = false;
    if (!method) {
      required = true;
    } else if (method === "SKRILL:WLT") {
      required = true;
    } else if (method.indexOf("CRYPTOPAY:") === 0) {
      required = true;
    } else if (method == "Interac") {
      required = true;
    } else if (method.indexOf("Counos:") === 0) {
      required = true;
    } else if (method.indexOf("COINSPAID:") === 0) {
      required = true;
    } else if (method.indexOf("WEBPAYMENT:") === 0) {
      required = true;
    }
    return required;
  };

  useEffect(() => {
    getWithdrawalMethods();
  }, [withdrawalOption]);
  // }, [withdrawalAmount, withdrawalOption]);

  return (
    <>
      <PageHeadline
        {...props}
        extendedLink={"/dashboard/cashier"}
        extendedText={"Cashier"}
        page_name={__("Withdraw")}
      />

      <div className="page_content_area">
        <div className="">
          <p className="text">{__("Use the form below to withdraw funds from your account.")}</p>
          {__("Amount that can be withdrawn:")} <b>{withdrawalDetails.total_withdrawal_amount}</b> USD
          <p className="text">
            {__("Total locked bonus amount:")} <b>{withdrawalDetails.locked_bonus_amount}</b> USD
            <br />
            {__("Total bet turnover:")} <b>{withdrawalDetails.bet_turnover}</b> USD
            <br />
            {__("Total bet amount necessary to unlock remaining bonus:")} <b>{withdrawalDetails.amount_2_unlock}</b> USD
            &nbsp;
            <br />
            &nbsp;
          </p>
          <p>
            {__("Choose payment system")}
            <br />
            &nbsp;
            <i>{__("*you can make a withdrawal only by using payment system you made deposit before")}</i>
            <br />
            &nbsp;
          </p>
          <ul className="withdrawalOptionMenu">
            <li>
              <input
                id="cryptoOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Crypto"}
                onChange={(ev) => setWithdrawalOption("Crypto")}
              />
              <label for="cryptoOption">{__("Crypto")}</label>
            </li>
            <li>
              <input
                id="wireTransferOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Wire Transfer"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Wire Transfer");
                }}
              />
              <label for="wireTransferOption">{__("Wire Transfer")}</label>
            </li>
            <li>
              <input
                id="creditCardsOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Credit Cards"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Credit Cards");
                }}
              />
              <label for="creditCardsOption">{__("Credit Cards")}</label>
            </li>
            <li>
              <input
                id="paypalOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Paypal"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Paypal");
                }}
              />
              <label for="paypalOption">{__("Paypal")}</label>
            </li>
            <li>
              <input
                id="othersOption"
                type="radio"
                name="withdrawalOption"
                checked={withdrawalOption === "Other"}
                onChange={(ev) => {
                  setWithdrawalError("");
                  setWithdrawalOption("Other");
                }}
              />
              <label for="othersOption">{__("Other")}</label>
            </li>
          </ul>
          {withdrawalOption === "Crypto" && (
            <>
              <p>{__("Choose Coin")}</p>
              <form method="post" id="withdrawForm" onSubmit={showWithdrwalConfirmation}>
                <div className="row">
                  {!isWalletAddressRequired(withdrawalMethod) && (
                    <div className="col-md-12 account_number_explanation">
                      <br />
                      <div className="alert alert-warning">
                        <b>{__("Withdrawals will be sent to the same account used for deposit with this method.")}</b>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 deposit-methods">
                    <div className="payment_method_list">
                      <div className="banking_area">
                        <div className="banking_list_area">
                          {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                          {withdrawalMethods.map((method, index) => (
                            <div className="single_banking e_wallet_method">
                              <input
                                id={"all_" + method.id}
                                onChange={() => setWithdrawalMethod(method.method_id)}
                                type="radio"
                                checked={method.method_id === withdrawalMethod}
                              />
                              <label htmlFor={"all_" + method.id}>
                                <ul>
                                  <li className="payment_logo_area">
                                    {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      <span className="helper"></span>
                                      <span className="method_logo_text">{__("Method Logo")}</span>
                                    </span>
                                    <div
                                      className="method_logo"
                                      style={{
                                        backgroundImage: "url(" + method.logo + ")",
                                      }}
                                    ></div>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="payment_method_name">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">{__("Method Name")}</span>
                                    <span className="payment_content"> {method.method}</span>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="min_payment_amount">
                                    <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                    <span className="payment_content">
                                      {__("MIN")} {method.min_withdrawal} USD
                                      <br />
                                      <i>
                                        **{method.withdrawal_fee}%{" "}
                                        {__("Transaction fee will be deducted from your withdrawal amount.")}
                                      </i>
                                    </span>
                                    <div className="clearfix"></div>
                                  </li>
                                </ul>
                              </label>
                              <label htmlFor={"all_" + method.id} className="payment_check_mark">
                                <i className="fas fa-check selected_method_icon"></i>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="number"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                      className="form-control"
                      placeholder={__("Amount")}
                    />
                    <input type="hidden" id="verifiedCode" className="form-control" name="verified_code" />
                    <br />
                  </div>
                  {isWalletAddressRequired(withdrawalMethod) && (
                    <div className="col-sm-12 account_number">
                      <input
                        required
                        type="text"
                        value={withdrawalWallet.toString()}
                        onChange={(ev) => setWithdrawalWallet(ev.target.value)}
                        className="form-control acc_number"
                        placeholder={__("Wallet Address")}
                      />
                      <br />
                    </div>
                  )}

                  <div className="col-sm-12 account_number">
                    <input
                      readOnly={true}
                      type="email"
                      value={userDetails.email}
                      className="form-control email_address"
                      placeholder={__("User Email")}
                    />
                    <br />
                  </div>
                  <div className="button_group">
                    <Link onClick={showWithdrwalConfirmation} className="commonBtn uppercase no_margin">
                      {__("Submit withdrawal")}
                    </Link>
                  </div>
                </div>
              </form>
            </>
          )}
          {(withdrawalOption === "Wire Transfer" ||
            withdrawalOption === "Credit Cards" ||
            withdrawalOption === "Paypal") && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => {
                                setWithdrawalMethod(method.method_id);
                              }}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="commonBtn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
          {withdrawalOption === "Other" && (
            <form method="post">
              <div className="row">
                <div className="col-12 deposit-methods">
                  <div className="payment_method_list">
                    <div className="banking_area">
                      <div className="banking_list_area">
                        {/* <div className="small_info">
                        {__(
                          "Click on the 'i' icon within a logo for more information about a payment method."
                        )}
                      </div> */}

                        {withdrawalMethods.map((method, index) => (
                          <div className="single_banking e_wallet_method">
                            <input
                              id={"all_" + method.id}
                              onChange={() => setWithdrawalMethod(method.method_id)}
                              type="radio"
                              checked={method.method_id === withdrawalMethod}
                            />
                            <label htmlFor={"all_" + method.id}>
                              <ul>
                                <li className="payment_logo_area">
                                  {/* <a
                                  target="_blank"
                                  href="https://www.counos.io/CounosX"
                                  className="icon_popover"
                                >
                                  i
                                </a> */}
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">
                                    <span className="helper"></span>
                                    <span className="method_logo_text">{__("Method Logo")}</span>
                                  </span>
                                  <div
                                    className="method_logo"
                                    style={{
                                      backgroundImage: "url(" + method.logo + ")",
                                    }}
                                  ></div>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="payment_method_name">
                                  <span className="helper"></span>
                                  <span className="payment_text_mobile">{__("Method Name")}</span>
                                  <span className="payment_content"> {method.method}</span>
                                  <div className="clearfix"></div>
                                </li>
                                <li className="min_payment_amount">
                                  <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                  <span className="payment_content">
                                    {__("MIN")} {method.min_withdrawal} USD
                                    <br />
                                    <i>
                                      **{method.withdrawal_fee}%{" "}
                                      {__("Transaction fee will be deducted from your withdrawal amount.")}
                                    </i>
                                  </span>
                                  <div className="clearfix"></div>
                                </li>
                              </ul>
                            </label>
                            <label htmlFor={"all_" + method.id} className="payment_check_mark">
                              <i className="fas fa-check selected_method_icon"></i>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="number"
                    value={withdrawalAmount}
                    onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    className="form-control"
                    placeholder={__("Amount")}
                  />
                  <br />
                </div>
                <div className="col-sm-12">
                  <input
                    type="text"
                    value={paymentSystem}
                    onChange={(ev) => setPaymentSystem(ev.target.value)}
                    className="form-control"
                    placeholder={__(" Enter payment system")}
                  />
                  <br />
                </div>
                <div className="col-sm-12 account_number">
                  <input
                    readOnly={true}
                    type="email"
                    value={userDetails.email}
                    className="form-control email_address"
                    placeholder={__("User Email")}
                  />
                  <br />
                </div>
              </div>
              <div className="button_group">
                <Link onClick={showWithdrwalConfirmationDirect} className="commonBtn uppercase no_margin">
                  {__("Submit withdrawal")}
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>

      <Modal show={withdrawalConfirmationModal} onHide={closeWithdrwalConfirmation} id="popup-withdraw">
        <Modal.Body className="login_modal">
          <div onClick={closeWithdrwalConfirmation} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="modal_headline">{__("Withdrawal Confirmation")}</div>
          {withdrawalError ? (
            <p>{withdrawalError}</p>
          ) : (
            <p>
              {__(
                "Are you sure you want to make a withdrawal? Any remaining Bonus Balance or Free Spins will be forfeited."
              )}{" "}
            </p>
          )}
          <div className="button_group d-flex gap_30">
            {!withdrawalError && (
              <button type="button" className="commonBtn uppercase no_margin ConfirmBtn" onClick={submitWithdraw}>
                {__("confirm")}
              </button>
            )}
            <button type="button" className="commonBtn red uppercase no_margin" onClick={closeWithdrwalConfirmation}>
              {__("Close")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
