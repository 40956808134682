import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import api from "services/api";
import { __ } from "services/Translator";
import { setDefaultCurrency } from "stores/balance/userBalance";
//New structured call
import BuyGoldCoins from "components/BuyGoldCoins";
import Swal from "sweetalert2";
import HeaderWalletBalance from "components/SweepCoinBalance";

function CoinSwitcher(props) {
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.userBalance);
  //Newly added for the component

  const [buyGoldPlanData, setBuyGoldPlanData] = useState({
    planId: "",
    planPrice: "",
    GC: 0,
    SC: 0,
    openWallet: false,
  });
  const [showBuyModal, setShowBuyModal] = useState(false);
  const callDepositModal = (receiveVal) => {
    console.log("receiveVal", receiveVal);
    setBuyGoldPlanData({
      ...buyGoldPlanData,
      planId: receiveVal.planId,
      planPrice: receiveVal.planPrice,
      openWallet: receiveVal.openWallet,
      GC: receiveVal.GC,
      SC: receiveVal.SC,
    });
  };

  const toggleCurrency = async (ev) => {
    var target = ev.target;
    var checked = ev.target.checked;
    var selectedBalance;
    if (userBalance.selectedBalance === "SC") {
      dispatch(setDefaultCurrency("GC"));
      selectedBalance = "GC";
    } else if (userBalance.selectedBalance === "GC") {
      dispatch(setDefaultCurrency("SC"));
      selectedBalance = "SC";
    }

    var response = await api.post("/sweepstake-switch-balance", {
      selectedBalance: selectedBalance,
    });

    if (response.status === 200) {
      dispatch(setDefaultCurrency(selectedBalance));
    } else {
      Swal.fire({
        title: "warning!",
        text: response.data.message,
        icon: "warning",
      });
    }
  };
  return (
    <>
      <BuyGoldCoins callDepositModal={callDepositModal} {...props} showBuyModal={showBuyModal} />
      <div className={"coinSwitcherArea " + props.className}>
        <div className="balanchArea">
          <div className="sweepCoinArea">
            <label onChange={toggleCurrency} class="currencyToggle ">
              {userBalance.selectedBalance == "SC" && <input type="checkbox" defaultChecked={true} />}
              {userBalance.selectedBalance == "GC" && <input type="checkbox" defaultChecked={false} />}

              <span class="switch">
                <span class="switch__slider"></span>
                <span class="switch__fixed-icon--left"></span>
                <span class="switch__fixed-icon--right"></span>
              </span>
            </label>

            <div className="courrentBalance">
              <span>
                {userBalance.selectedBalance === "SC"
                  ? userBalance.cash_balance + " SC"
                  : userBalance.bonus_balance + " GC"}
              </span>
            </div>
          </div>
        </div>
        <div className="actionArea">
          <Link
            id="buyCoinsButton"
            onClick={() => {
              setShowBuyModal(true);
              setTimeout(function () {
                setShowBuyModal(false);
              }, 500);
            }}
            className="commonBtn purpleBtn"
          >
            Buy
          </Link>
        </div>
      </div>
      <HeaderWalletBalance buyGoldPlanData={buyGoldPlanData} {...props} />
    </>
  );
}

export default CoinSwitcher;
