import React, { useState, useEffect, useRef, useContext } from "react";
// import { liveChatUrl } from "../configs/config.js";
import api from "services/api";
import { Modal, Dropdown, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateUserBalance } from "stores/balance/userBalance";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import { usePlaidLink } from "react-plaid-link";

// import "../../../styles/_header_wallet.scss";
import Spinner from "elements/Spinner";

const SweepCoinBalance = (props) => {
  const __ = (text) => text;
  const dispatch = useDispatch();
  //GamePlay Page detection
  const location = useLocation();

  const [showInPlay, setShowInPlay] = useState(false);
  const playPath = location.pathname.split("/");
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setShowInPlay(playPath.includes("play"));
  }, [playPath]);

  //Get user balance functionality

  const isAuthenticated = auth.isAuthenticated;
  const userBalance = useSelector((state) => state.userBalance);
  const { getUserBalance } = useContext(AppContext);

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    // console.log(response);
    if (response.status === 200) {
      dispatch(updateUserBalance(response.data));
      ls.set("conversionRates", response.data.rates);
    }
  };

  const closeWalletModal = () => {
    props.setShowWalletModal(false);
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );
  const [hideEmptyWallets, setHideEmptyWallets] = useState(
    ls.get("hideEmptyWallets") ? ls.get("hideEmptyWallets") : false
  );
  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );
  const [showFiatChooserModal, setShowFiatChooserModal] = useState(false);
  const toggleShowBalanceAsFiat = () => {
    if (!showBalanceAsFiat) {
      setShowFiatChooserModal(true);
    }
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
  };
  const toggleHideEmptyWallets = () => {
    ls.set("hideEmptyWallets", !hideEmptyWallets);
    setHideEmptyWallets(!hideEmptyWallets);
  };
  const fiatToDisplaySelected = (ev) => {
    ls.set("fiatCurrencyToDisplay", ev.target.value);
    setFiatCurrencyToDisplay(ev.target.value);
  };
  const closeFiatChooserModal = () => {
    setShowFiatChooserModal(false);
  };

  const wageringTooltipRef = useRef(null);
  const [wageringTooltipShow, setWageringTooltipShow] = useState(false);
  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const [activeWalletTab, setActiveWalletTab] = useState("deposit");

  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  //Deposit functinoality START's here
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);

  const [depositData, setDepositData] = useState({
    amount: 0,
    method: "",
    campaign: "no_bonus",
    planId: 0,
  });

  const getDepositMethods = async (amount) => {
    var effectiveAmount = depositAmount;
    // console.log("effectiveAmount", effectiveAmount);
    if (!effectiveAmount && amount) {
      effectiveAmount = amount;
    }
    if (effectiveAmount) {
      var response = await api.post("/deposit/methods", {
        amount: effectiveAmount,
      });

      if (response.status === 200) {
        setSpinner(false);
        setDepositMethods(response.data.methods);
        if (!response.data.methods.filter((method) => method.method_id === depositMethod?.method_id).length) {
          setDepositMethod(null);
        }

        return response.data.methods;
      }
    }
  };

  useEffect(() => {
    setSpinner(true);
    getDepositMethods();
  }, [depositAmount]);
  //Deposit functionality END's here

  //Withdrawal functionality START's here

  const buyCoins = async () => {
    if (props.buyGoldPlanData.openWallet) {
      if (props.buyGoldPlanData.planPrice) {
        setDepositAmount(props.buyGoldPlanData.planPrice);
        setDepositData({
          ...depositData,
          ["amount"]: props.buyGoldPlanData.planPrice,
          ["planId"]: props.buyGoldPlanData.planId,
        });
        props.setShowWalletModal(true);
      } else {
        var depData = {
          ...depositData,
          ["amount"]: props.buyGoldPlanData.planPrice,
          ["planId"]: props.buyGoldPlanData.planId,
        };

        props.setSpinner(true);
        var response = await api.post("/sweepcoin-purchase", depData);
        props.setSpinner(false);
        console.log("depositResponse", response);
        if (response.status === 200 && response.data.status === "APPROVED") {
          props.showAlert(__("Success"), __(response.data.message));
          closeWalletModal();
        } else {
          props.showAlert("Warning!!", response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    buyCoins();
  }, [props.buyGoldPlanData]);

  useEffect(() => {
    // console.log("depositData", depositData);
  }, []);

  const submitDeposit = async (event) => {
    event.preventDefault();

    props.setSpinner(true);

    if (depositMethod?.method_id == "MyUser:CC") {
      var valid = validateCCForm();
      if (!valid) {
        props.setSpinner(false);
        return;
      }

      depositData.creditCardInfo = creditCardInfo;
      depositData.riskSessionId = riskSessionId;
    } else if (depositMethod?.method_id == "Dwolla") {
      var valid = validateBankForm();
      if (!valid) {
        props.setSpinner(false);
        return;
      }
      depositData.bankForm = bankForm;
    }

    depositData.method = depositMethod?.method_id;

    props.setSpinner(true);
    var response = await api.post("/sweepcoin-purchase", depositData);
    props.setSpinner(false);
    // console.log("depositResponse", response);
    if (response.status === 200) {
      if (response.data.status === "APPROVED" && response.data.fields_for === "MyUser:CC") {
        // setDepositFinalData(response.data.data);

        props.showAlert(__("Success"), __(response.data.message));

        setCreditCardInfo({
          NameOnCC: "",
          CardNumber: "",
          ExpMonth: "",
          ExpYear: "",
          CVV: "",
        });
        closeWalletModal();
      } else if (response.data.status === "additional_fields") {
      } else if (response.data.status === "redirect") {
        window.location.href = response.data.redirectUrl;
      } else if (response.data.status === "APPROVED" && depositData.method == "Dwolla") {
        props.showAlert("Success!!", response.data.message);
        closeWalletModal();
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const script = document.createElement("script");
  const [riskSessionId, setRiskSessionId] = useState(null);

  const loadMyUserPay = () => {
    if (window.MyUserPay) {
      console.log("loadMyUserPay Called");

      window.MyUserPay.setKey("pk_test_545a51eba2758d8f77979b0b5c10f038");
      window.MyUserPay.createRiskSession(
        function (data) {
          console.log("createRiskSession", data);
          if (data.status == 1) {
            setRiskSessionId(data.id); //send it to us via server request using $sent_data[‘risk_session_id’]
          }
        },
        {
          connection_token: "u_8d8ee1b06974d8c0ac0f72227bda63d0",
          processor_connection_token: "default",
        }
      );
    }
  };

  useEffect(() => {
    loadMyUserPay();
  }, []);

  const [bankForm, setBankForm] = useState({
    bankAccID: "",
    name: "", //Account name
  });

  const [bankErrors, setBankErrors] = useState({
    routingNumber: "",
    accountNumber: "",
    bankAccountType: "",
    name: "",
  });

  const updateBankInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setBankForm({ ...bankForm, [field]: value });
  };

  const validateBankForm = () => {
    var valid = true;
    var errorsTmp = {};

    if (!bankForm.bankAccID) {
      errorsTmp = {
        ...errorsTmp,
        bankAccount: __("Please select a bank or add a new one"),
      };
      valid = false;
    }

    setBankErrors(errorsTmp);
    return valid;
  };

  const [linkToken, setLinkToken] = useState(null);

  const createLinkToken = async () => {
    var tokenResponse = await api.get("/dwolla/plaid/link/create");
    if (tokenResponse.status === 200) {
      var linkToken = tokenResponse.data.link_token;
      setLinkToken(linkToken);
      console.log(`Created Link Token: ${linkToken}`);
    }
  };

  useEffect(() => {
    if (createLinkToken && !linkToken) {
      createLinkToken().catch((err) => console.error(err));
    }
  }, []);

  const handlePlaidLinkSuccess = async (publicToken, metadata) => {
    console.log("publicToken", publicToken);
    console.log("metadata", metadata);

    var response = await api.post("/dwolla/plaid/funding-source/create", {
      publicToken: publicToken,
      accounts: metadata.accounts,
    });

    console.log(response);
    var methods = await getDepositMethods(20); //Setting dummy amount to get all methods. This method is cached and somehow always assumes depositMethod = 0
    console.log(methods);
    for (var i in methods) {
      var method = methods[i];
      if (method?.method_id == "Dwolla") {
        setDepositMethod(method);
      }
    }
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
  });

  const removeDwollaBank = async (bankAccID) => {
    if (window.confirm(__("Are you sure to remove this bank account?"))) {
      var response = await api.post("/dwolla/funding-source/remove", {
        bankAccID,
      });
      if (response.status === 200) {
        var methods = await getDepositMethods();
        for (var i in methods) {
          var method = methods[i];
          if (method?.method_id == "Dwolla") {
            setDepositMethod(method);
          }
        }
      }
    }
  };

  const [creditCardInfo, setCreditCardInfo] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  const [cCErrors, setCcErrors] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  const updateCCInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    setCreditCardInfo({ ...creditCardInfo, [field]: value });
  };

  const validateCCForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (creditCardInfo.NameOnCC.length < 2 || creditCardInfo.NameOnCC.length > 250) {
      errorsTmp = { ...errorsTmp, NameOnCC: __("Please enter the name") };
      valid = false;
    }

    if (creditCardInfo.CardNumber.length < 2 || creditCardInfo.CardNumber.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (creditCardInfo.CardNumber.length < 2 || creditCardInfo.CardNumber.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpMonth.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpMonth: __("Please enter the expiry month"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpYear.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpYear: __("Please enter the expiry year"),
      };
      valid = false;
    }

    if (creditCardInfo.CVV.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        CVV: __("Please enter the CVV"),
      };
      valid = false;
    }

    setCcErrors(errorsTmp);
    return valid;
  };

  return (
    <>
      <Modal className="backdrop_buy" show={props.showWalletModal} onHide={closeWalletModal}>
        <Modal.Body className="buy_modal">
          <div class="buy_coins">
            <div class="buy_coin_header">
              <div onClick={closeWalletModal} className="modal_close">
                <i className="fal fa-times"></i>
              </div>
              <div className="page_title golden text-center">{__("Purchase")}</div>
            </div>
            <div className={"buy_coins_content select_payment_method"}>
              {spinner && <Spinner />}
              <br />
              <br />
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {activeWalletTab === "deposit" && (
                      <>
                        <div className="order_summary_block">
                          <h3 className="order_summary_title">{__("Order Summary")}</h3>
                          <div className="currency_amount">${props.buyGoldPlanData.planPrice} USD</div>
                          <div className="coin_amount">
                            {props.buyGoldPlanData.GC + __(" gold coins")}

                            {props.buyGoldPlanData.SC
                              ? " and " + props.buyGoldPlanData.SC + __(" sweepstake coins free")
                              : "."}
                          </div>
                        </div>

                        <div className="other_method_area">
                          <ul>
                            {depositMethods.map((method, index) => (
                              <li className={method?.method_id === depositMethod?.method_id ? "active" : ""}>
                                <input
                                  id={"all_" + method.id}
                                  onChange={() => {
                                    setDepositMethod(method);
                                  }}
                                  type="radio"
                                  checked={method?.method_id === depositMethod?.method_id}
                                />
                                <label
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  htmlFor={"all_" + method.id}
                                >
                                  <img alt="" src={method.logo} />
                                  &nbsp; &nbsp;{method.method}
                                </label>
                              </li>
                            ))}
                          </ul>

                          <div
                            className={
                              "credit_card_from_area " +
                              (depositMethod?.method_id == "MyUser:CC" ? "d-block" : "d-none")
                            }
                          >
                            <div className="form_area">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input_item">
                                    <div className="name">
                                      {__("Card Name")}
                                      <span className="requried_icon">*</span>
                                    </div>
                                    <input
                                      type="text"
                                      name="NameOnCC"
                                      class={"form-control name_value "}
                                      value={creditCardInfo.NameOnCC}
                                      onChange={updateCCInput}
                                    />
                                    <div className="errorMsg">{cCErrors.NameOnCC}</div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input_item">
                                    <div className="name">
                                      {__("Card Number")}
                                      <span className="requried_icon">*</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="CardNumber"
                                      class={"form-control name_value "}
                                      value={creditCardInfo.CardNumber}
                                      onChange={updateCCInput}
                                    />
                                    <div className="errorMsg">{cCErrors.CardNumber}</div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input_item">
                                    <div className="name">
                                      {__("Expiry Month")}
                                      <span className="requried_icon">*</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="ExpMonth"
                                      step="1"
                                      min="1"
                                      max="12"
                                      placeholder="EX: 01"
                                      class={"form-control name_value "}
                                      value={creditCardInfo.ExpMonth}
                                      onChange={updateCCInput}
                                    />
                                    <div className="errorMsg">{cCErrors.ExpMonth}</div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input_item">
                                    <div className="name">
                                      {__("Expiry Year")}
                                      <span className="requried_icon">*</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="ExpYear"
                                      step="1"
                                      min="1900"
                                      placeholder="EX: 2022"
                                      value={creditCardInfo.ExpYear}
                                      onChange={updateCCInput}
                                      class={"form-control name_value "}
                                    />
                                    <div className="errorMsg">{cCErrors.ExpYear}</div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input_item">
                                    <div className="name">
                                      {__("CVV")}
                                      <span className="requried_icon">*</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="CVV"
                                      class={"form-control name_value "}
                                      value={creditCardInfo.CVV}
                                      onChange={updateCCInput}
                                    />
                                    <div className="errorMsg">{cCErrors.CVV}</div>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            </div>
                          </div>

                          {depositMethod?.method_id == "Dwolla" && (
                            <div>
                              {depositMethod.fundingSources && (
                                <div className="existing_accounts">
                                  <div className="subheading">
                                    {__("Select a bank account")}
                                    <span className="requried_icon">*</span>
                                  </div>
                                  {depositMethod.fundingSources.map((item, index) => (
                                    <div className="bank_account_list_item">
                                      <a
                                        className={
                                          "common_button bank_account_name " +
                                          (bankForm.bankAccID === item._links.self.href ? "active" : "")
                                        }
                                        onClick={() =>
                                          setBankForm({
                                            ...bankForm,
                                            bankAccID: item._links.self.href,
                                          })
                                        }
                                      >
                                        {item.name}
                                      </a>
                                      <a
                                        className="remove_bank"
                                        onClick={() => removeDwollaBank(item._links.self.href)}
                                      >
                                        <span className="fas fa-times"></span>
                                      </a>
                                    </div>
                                  ))}
                                  <div className="bank_account_list_item">
                                    <a
                                      className="common_button bank_account_name"
                                      // onClick={() => setBankForm({ ...bankForm, bankAccID: "" })}
                                      onClick={() => openPlaidLink()}
                                    >
                                      {__("New account")}
                                    </a>
                                  </div>

                                  <div className="errorMsg">{bankErrors.bankAccount}</div>
                                </div>
                              )}
                              {true ||
                                !!bankForm.bankAccID || ( //This block will be used if we use manual bank account addition, currenlty using plaid integration to add bank
                                  <div className="form_area">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="input_item">
                                          <div className="name">
                                            {__("Routing Number")}
                                            <span className="requried_icon">*</span>
                                          </div>
                                          <input
                                            type="text"
                                            name="routingNumber"
                                            class={"form-control name_value "}
                                            value={bankForm.routingNumber}
                                            onChange={updateBankInput}
                                          />
                                          <div className="errorMsg">{bankErrors.routingNumber}</div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="input_item">
                                          <div className="name">
                                            {__("Account Type")}
                                            <span className="requried_icon">*</span>
                                          </div>
                                          <select
                                            name="bankAccountType"
                                            class={"form-control name_value "}
                                            value={bankForm.bankAccountType}
                                            onChange={updateBankInput}
                                          >
                                            <option value="">{__("Select")}</option>
                                            <option value="checking">{__("Checking")}</option>
                                            <option value="savings">{__("Savings")}</option>
                                          </select>
                                          <div className="errorMsg">{bankErrors.bankAccountType}</div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="input_item">
                                          <div className="name">
                                            {__("Account Number")}
                                            <span className="requried_icon">*</span>
                                          </div>
                                          <input
                                            type="text"
                                            name="accountNumber"
                                            class={"form-control name_value "}
                                            value={bankForm.accountNumber}
                                            onChange={updateBankInput}
                                          />
                                          <div className="errorMsg">{bankErrors.accountNumber}</div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="input_item">
                                          <div className="name">
                                            {__("Account Name")}
                                            <span className="requried_icon">*</span>
                                          </div>
                                          <input
                                            type="text"
                                            name="name"
                                            class={"form-control name_value "}
                                            value={bankForm.name}
                                            onChange={updateBankInput}
                                          />
                                          <div className="errorMsg">{bankErrors.name}</div>
                                        </div>
                                      </div>

                                      <div className="clearfix"></div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}

                          <div className="center">
                            <Link className="common_button" onClick={submitDeposit}>
                              Submit
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SweepCoinBalance;
