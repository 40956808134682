import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Logo = (props) => {
  // const menuOpen = props.menuOpen;
  const [categoryName, setCategoryName] = useState("Slots");
  const __ = (text) => text;

  return (
    <>
      <Link to="/" className="logo">
        <img
          alt=""
          src={require("../assets/sweeplay/images/logos/logo.svg").default}
        />
        {!props.isMobileDevice && <span>Sweeplay</span>}
      </Link>
    </>
  );
};

export default Logo;
