import React from "react";
import { useSelector } from "react-redux";
import InactivityChecker from "elements/InactivityChecker";
import { OpenRoutes, PrivateRoutes } from "routes/router";
import AppContext from "contexts/AppContext";
import Spinner from "elements/Spinner";
// ----------------------------------------------------------------------

export default function AuthGuard(props) {
  const [isReady, setIsReady] = React.useState(false);
  const isLoggedIn = useSelector((state) => state.userAuth.isAuthenticated);

  const { updateUserAuthStatus, setLoginBonus } = React.useContext(AppContext);

  const init = async () => {
    await updateUserAuthStatus();
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (isLoggedIn) {
      setLoginBonus();
    }
  }, [isLoggedIn]);

  if (isReady) {
    if (!isLoggedIn) {
      return <OpenRoutes {...props} />;
    } else {
      return (
        <React.Fragment>
          <InactivityChecker />
          <PrivateRoutes {...props} />
        </React.Fragment>
      );
    }
  } else {
    return <Spinner />;
  }
}
