import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import api from "services/api";
import RemainintTime from "elements/RemainingTime";
import { __ } from "services/Translator";

export default function WheelOfFortune(props) {
  const [fortuneWheel, setFortuneWheel] = useState(null); //THe database object of the wheel data
  const [wheelResult, setWheelResult] = useState(null);

  const [theWheel, setTheWheel] = useState(null); //The wheel object
  const [wheelSpinning, setWheelSpinning] = useState(null);
  const [wheelImage, setWheelImage] = useState(new Image());

  const wheelImagePaths = {
    diamond: require("assets/sweeplay/images/wheels/diamond-wheel.png"),
    gold: require("assets/sweeplay/images/wheels/gold-wheel.png"),
    silver: require("assets/sweeplay/images/wheels/silver-wheel.png"),
    bronze: require("assets/sweeplay/images/wheels/bronze-wheel.png"),
  };

  const Winwheel = window.Winwheel;

  const getWheelOfFortune = async () => {
    if (props.usePropsWheel.wheel) {
      //If wheel object is passed from caller component, use that instead
      setFortuneWheel(response.data);
    } else {
      var response = await api.get("/rewards/fortune-wheel/status");
      if (response.status === 200) {
        if (response.data) {
          setFortuneWheel(response.data.wheel);
          setWheelResult(null);
        } else {
          setFortuneWheel(null);
        }
      } else {
        setFortuneWheel(null);
      }
    }
  };

  const playSound = () => {
    // Create audio object and load tick.mp3 file.
    var audio = new Audio(require("assets/sweeplay/sounds/tick.mp3").default);
    // Stop and rewind the sound if it already happens to be playing.
    audio.pause();
    audio.currentTime = 0;
    // Play the sound.
    audio.play();
  };

  const alertPrize = (indicatedSegment) => {
    Swal.fire({
      title: __("Congratulations!"),
      html: __("You have won") + " " + indicatedSegment.text + " " + __("of your wagers from yesterday."),
      icon: "success",
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Claim Bonus!',
      confirmButtonAriaLabel: "Claim Bonus!",
      cancelButtonText: "Skip",
      cancelButtonAriaLabel: "Skip Bonus",
    }).then(async (result) => {
      resetWheel();
      getWheelOfFortune();
    });
  };

  const resetWheel = () => {
    theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
    theWheel.rotationAngle = -16;
    theWheel.wheelImage = wheelImage;
    theWheel.draw(); // Call draw to render changes to the wheel.
    setWheelSpinning(false);
    setTheWheel(theWheel);
  };

  const initWheel = () => {
    var wheelConfig = {
      numSegments: 12, // Specify number of segments.
      outerRadius: 253, // Set outer radius so wheel fits inside the background.
      drawMode: "image", // drawMode must be set to image.
      drawText: false, // Need to set this true if want code-drawn text on image wheels.
      drawText: true, // Need to set this true if want code-drawn text on image wheels.
      textFontSize: 0, // Set text options as desired.
      textOrientation: "curved",
      textDirection: "normal",
      textAlignment: "outer",
      textMargin: 35,
      textFontFamily: "monospace",
      textStrokeStyle: "black",
      textLineWidth: 2,
      textFillStyle: "white",
      rotationAngle: -16,
      // pointerAngle: 16,
      // Define segments.

      segments: fortuneWheel.fortune_type.fortune_prizes.map((item, index) => {
        return { text: item.title };
      }),
      // Specify the animation to use.
      animation: {
        type: "spinToStop",
        duration: 10, // Duration in seconds.
        spins: 10, // Number of complete spins.
        callbackSound: playSound,
      },
    };

    var wheel = new Winwheel(wheelConfig);
    wheelImage.width = 510;
    wheelImage.height = 510;
    console.log("wheelImage", wheelImage);

    wheelImage.src = wheelImagePaths[fortuneWheel.fortune_type.slug];
    setWheelImage(wheelImage);
    setTheWheel(wheel);
  };

  const startSpin = async () => {
    if (!wheelSpinning) {
      setWheelSpinning(true);
      var response = await api.post("/rewards/fortune-wheel/spin", { player_fortune_id: fortuneWheel.id });
      if (response.status === 200) {
        if (response.data) {
          setWheelResult(response.data);
          theWheel.animation.stopAngle = response.data.win_point;
          theWheel.animation.callbackFinished = alertPrize;
          theWheel.startAnimation();
        } else {
          setWheelSpinning(false);
          setWheelResult(null);
        }
      } else {
        setWheelSpinning(false);
        setWheelResult(null);
      }
    }
  };

  useEffect(() => {
    getWheelOfFortune();
  }, []);
  useEffect(() => {
    if (fortuneWheel) {
      initWheel();
    }
  }, [fortuneWheel]);

  useEffect(() => {
    if (wheelImage && theWheel) {
      wheelImage.onload = function () {
        theWheel.wheelImage = wheelImage;
        theWheel.draw();
        setTheWheel(theWheel);
      };
    }
  }, [wheelImage, theWheel]);

  return (
    <>
      {fortuneWheel ? (
        <div className="wheel_top_content">
          <div className="item left">
            <img className="left_vector" src={require("assets/sweeplay/images/rewards/left-side-wheel.png")} />
          </div>

          <div className="item">
            <div className={"wheel_container " + fortuneWheel.fortune_type.slug}>
              <div className="wheel_canvas_outer">
                {fortuneWheel.fortune_type.slug === "diamond" ? (
                  <img src={require("assets/sweeplay/images/wheels/diamond-frame.png")} className="wheel_frame" />
                ) : fortuneWheel.fortune_type.slug === "gold" ? (
                  <img src={require("assets/sweeplay/images/wheels/gold-frame.png")} className="wheel_frame" />
                ) : fortuneWheel.fortune_type.slug === "silver" ? (
                  <img src={require("assets/sweeplay/images/wheels/silver-frame.png")} className="wheel_frame" />
                ) : fortuneWheel.fortune_type.slug === "bronze" ? (
                  <img src={require("assets/sweeplay/images/wheels/bronze-frame.png")} className="wheel_frame" />
                ) : (
                  <></>
                )}
                <canvas id="canvas" class="wheel_canvas" width="506" height="506">
                  <p align="center">{__("Sorry, your browser doesn't support canvas. Please try another")}.</p>
                </canvas>
              </div>

              <div className="claim_button">
                <button className="commonBtn purpleBtn" onClick={startSpin}>
                  {__("Spin")}
                </button>
              </div>
            </div>
          </div>
          <div className="item right">
            <img className="right_vector" src={require("assets/sweeplay/images/rewards/right-side-wheel.png")} />
          </div>
        </div>
      ) : (
        <>
          {props.pendingWheelStatus && props.pendingWheelStatus.wheel ? (
            <>
              <div className="wheel_top_content">
                <div className="item left">
                  <img className="left_vector" src={require("assets/sweeplay/images/rewards/left-side-wheel.png")} />
                </div>

                <div className="item">
                  <div className={"wheel_container " + props.pendingWheelStatus.wheel}>
                    <div className="wheel_canvas_outer">
                      {props.pendingWheelStatus.wheel === "diamond" ? (
                        <img src={require("assets/sweeplay/images/wheels/diamond-frame.png")} className="wheel_frame" />
                      ) : props.pendingWheelStatus.wheel === "gold" ? (
                        <img src={require("assets/sweeplay/images/wheels/gold-frame.png")} className="wheel_frame" />
                      ) : props.pendingWheelStatus.wheel === "silver" ? (
                        <img src={require("assets/sweeplay/images/wheels/silver-frame.png")} className="wheel_frame" />
                      ) : props.pendingWheelStatus.wheel === "bronze" ? (
                        <img src={require("assets/sweeplay/images/wheels/bronze-frame.png")} className="wheel_frame" />
                      ) : (
                        <></>
                      )}
                      <img
                        class="wheel_canvas"
                        width="506"
                        height="506"
                        src={wheelImagePaths[props.pendingWheelStatus.wheel]}
                      />
                    </div>

                    <div className="claim_button">
                      <button className="commonBtn purpleBtn" disabled="disabled" class="disabled">
                        {__("Spin")}
                      </button>
                      <div className="claim_countdown">
                        <span>{__("Can be claimed after:")} </span>
                        <RemainintTime timestamp={props.pendingWheelStatus.canBeClaimedAfter} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item right">
                  <img className="right_vector" src={require("assets/sweeplay/images/rewards/right-side-wheel.png")} />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
