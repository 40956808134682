import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Banner from "elements/Banner";
import { Button, Badge, Modal } from "react-bootstrap";
import Flickity from "react-flickity-component";
import DOMPurify from "dompurify";
import api from "services/api";

export default function Promotions(props) {
  const __ = (text) => text;
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const isAuthenticated = props.isAuthenticated;

  const [promotions, setPromotions] = useState([]);

  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "bonus_terms_conditions",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect( () => {
    getContentPage();
  }, []);

  const [show, setShow] = useState(0);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <div className="landing">
        {/* <PageHeadline page_name={__("Promotions")} /> */}
        <div className="main_content_area">
          <div className="container-xxl">
            <div className="promotions_area">
              <div className="promotions_heading">
                <div className="promotions_heading_text">{__("Promotions")}</div>
              </div>
              <div className="promotions_item_area">
                <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("First")}
                    </Badge>
                    <p className="promotion_title">Double Your Gold</p>
                    <p className="promotion_description">
                      Get a 100% extra Gold Coins on your first deposit, this goes for any coins package!
                    </p>
                    {/* <Link className="read_more_btn">Read More {">"}</Link> */}
                  </div>
                  <div className="promotion_image_area">
                    <img src={require("assets/sweeplay/images/promotions/promotion-img-6.png")} alt="" />
                  </div>
                </article>
                <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("Second")}
                    </Badge>
                    <p className="promotion_title">Daily Spin Extravaganza</p>
                    <p className="promotion_description">
                      Spin the Sweeplay Wheel of Fortune weekly for a chance to win up to 5,000,000 Gold Coins and other
                      exciting prizes!
                    </p>
                  </div>
                  <div className="promotion_image_area">
                    <img src={require("assets/sweeplay/images/promotions/promotion-img-2.png")} alt="" />
                  </div>
                </article>
                <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("Third")}
                    </Badge>
                    <p className="promotion_title">Weekend Tournament Showdown</p>
                    <p className="promotion_description">
                      Compete in our weekend tournaments, climb the leaderboard for a chance to win loads of Gold Coins
                      and Sweeplay Coins prizes!
                    </p>
                  </div>
                  <div className="promotion_image_area">
                    <img src={require("assets/sweeplay/images/promotions/promotion-img-1.png")} alt="" />
                    {/* <img src={require("assets/sweeplay/images/promotions/promotion-img-3.png")} alt="" /> */}
                  </div>
                </article>
                <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("Fourth")}
                    </Badge>
                    <p className="promotion_title">VIP Milestone Bonus</p>
                    <p className="promotion_description">
                      Reach a new VIP level and enjoy a special bonus package including Gold Coins, Sweeplay Coins, and
                      exclusive perks!
                    </p>
                  </div>
                  <div className="promotion_image_area">
                    <img src={require("assets/sweeplay/images/promotions/promotion-img-4.png")} alt="" />
                  </div>
                </article>
                <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("Fifth")}
                    </Badge>
                    <p className="promotion_title">Refer a Friend – Get 500,000 Gold Coins</p>
                    <p className="promotion_description">
                      Invite your friends to join Sweeplay and earn 500,000 Gold Coins, and 20 Sweeplay Coins for each
                      successful referral!
                    </p>
                  </div>
                  <div className="promotion_image_area">
                    <img src={require("assets/sweeplay/images/promotions/promotion-img-5.png")} alt="" />
                  </div>
                </article>
                {/* <article className="single_promotion blackBtnBG">
                  <div className="promotion_content_area">
                    <Badge bg="warning" text="dark">
                      {__("Fifth")}
                    </Badge>
                    <p className="promotion_title">Wednesday Whirlwind - 75% bonus + 25 Free Spins</p>
                    <p className="promotion_description">Wednesday Winnings coming your way!</p>
                  </div>
                  <div className="promotion_image_area"></div>
                </article> */}
              </div>
              <br />

              <center>
                <Link
                  onClick={() => {
                    setShow(true);
                  }}
                  className="commonBtn bannerBtn"
                >
                  {__("More Info")}
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} dialogClassName="modal-90w" onHide={() => setShow(false)}>
        <Modal.Body>
          <div
            onClick={() => {
              setShow(false);
            }}
            className="modal_close_reg small"
          >
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="bonusTerms">
            <h1 className="page_title">{pageContent && pageContent.content_data.page_title}</h1>

            <p className="paragraph desc">{pageContent && renderHTML(pageContent.content_data.content)}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
