import React, { useEffect } from "react";
import SelectBox from "elements/SelectBox";
import { __ } from "services/Translator";

const DateSelector = (props) => {
  const value = props.value
    ? props.value.toString().split("-")
    : ["0000", "00", "00"];

  const updateValue = (newValue, field) => {
    if (props.onChange) {
      var valueUpdate = JSON.parse(JSON.stringify(value));
      valueUpdate[field] = newValue;
      props.onChange(valueUpdate.join("-"));
    }
  };

  const dates = [...Array(31).keys()].map((val) => {
    return {
      label: (val + 1).toString(),
      value: (val + 1).toString().padStart(2, "0"),
    };
  });
  dates.unshift({ label: __("Date"), value: "00" });

  const months = [
    { label: __("Month"), value: "00" },
    { label: __("January"), value: "01" },
    { label: __("February"), value: "02" },
    { label: __("March"), value: "03" },
    { label: __("April"), value: "04" },
    { label: __("May"), value: "05" },
    { label: __("June"), value: "06" },
    { label: __("July"), value: "07" },
    { label: __("August"), value: "08" },
    { label: __("September"), value: "09" },
    { label: __("October"), value: "10" },
    { label: __("November"), value: "11" },
    { label: __("December"), value: "12" },
  ];

  const startYear =
    props.startYear && !isNaN(parseInt(props.startYear))
      ? parseInt(props.startYear)
      : 1950;
  const endYear =
    props.endYear && !isNaN(parseInt(props.endYear))
      ? parseInt(props.endYear)
      : 2050;

  const years = [...Array(endYear - startYear + 1).keys()].map((val) => {
    return {
      label: (val + startYear).toString(),
      value: (val + startYear).toString(),
    };
  });
  years.unshift({ label: __("Year"), value: "0000" });
  if (props.reverseYear) {
    years.reverse();
    console.log("years", years);
  }
  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <div className="col-4">
        <SelectBox
          className="form-control name_value"
          items={dates}
          value={value[2]}
          onChange={(itemValue) => updateValue(itemValue, 2)}
        />
      </div>
      <div className="col-4">
        <SelectBox
          className="form-control name_value"
          items={months}
          value={value[1]}
          onChange={(itemValue) => updateValue(itemValue, 1)}
        />
      </div>
      <div className="col-4">
        <SelectBox
          className="form-control name_value"
          items={years}
          value={value[0]}
          onChange={(itemValue) => updateValue(itemValue, 0)}
        />
      </div>
    </>
  );
};

export default DateSelector;
