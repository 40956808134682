import React, { useState, useEffect, useCallback } from "react";
import { __ } from "services/Translator";
import ProgressBar from "react-bootstrap/ProgressBar";
import WheelOfFortune from "elements/WheelOfFortune";
import { Modal, Button, Alert } from "react-bootstrap";
import api from "services/api";

export default function RewardSystem(props) {
  const [wheelWagerStatus, setWheelWagerStatus] = useState({});
  const [fortuneWheel, setFortuneWheel] = useState(null); //THe database object of the wheel data

  const [showWheel, setShowWheel] = useState(false); //The wheel object
  const closeAllModals = () => {
    setShowWheel(false);
  };

  const getWheelOfFortune = async () => {
    var response = await api.get("/rewards/fortune-wheel/status");
    if (response.status === 200) {
      setFortuneWheel(response.data.wheel);
      if (response.data.wheel) {
        setShowWheel(true);
      }
      setWheelWagerStatus(response.data.wagerStatus);
    }
  };

  useEffect(() => {
    getWheelOfFortune();
  }, []);

  return (
    <>
      <Modal className="fortune_wheel_modal" show={showWheel} onHide={closeAllModals}>
        <Modal.Body className="login_modal forgot_pass">
          <div className="reward_system">
            {/* Wheel of Fortune Block */}
            <section className="lucky_wheel">
              <WheelOfFortune
                pendingWheelStatus={wheelWagerStatus}
                wheel={fortuneWheel}
                usePropsWheel={true}
                {...props}
              />

              <div className="wheel_text_area">
                <div className="headline_colored">{__("Sweeplay Wheel Of Fortune")}</div>
                <div className="dsc">
                  {__("Spin the wheel each day for a chance to win bonuses from your previous days play.")}
                </div>
                <div className="progress_bar">
                  {props.isMobileDevice && (
                    <>
                      <div className="progressLabelMobile">{wheelWagerStatus.message}</div>
                    </>
                  )}

                  <ProgressBar
                    now={wheelWagerStatus.progress}
                    label={
                      <span className="progressLabel">{!props.isMobileDevice ? wheelWagerStatus.message : ""}</span>
                    }
                  />
                  <div className="row">
                    <div className="col-md-2 progress_subtitle">
                      {wheelWagerStatus.wheel_text ? wheelWagerStatus.wheel_text : ""}
                    </div>
                    <div className="col-md-8">&nbsp;</div>
                    <div className="col-md-2 text-right progress_subtitle">
                      {wheelWagerStatus.next_wheel_text ? wheelWagerStatus.next_wheel_text : ""}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}
