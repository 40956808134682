import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import api from "services/api";
import BuyGoldCoins from "elements/BuyGoldCoins";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

//New structured import
import { AngleRight, GiftBox, HeartIcon } from "./Icons";
import Translator, { __ } from "services/Translator";
import UserDropDownMenu from "components/UserDropDownMenu";
import CoinSwitcher from "components/CoinSwitcher";

import Logo from "elements/Logo";

const Header = (props) => {
  const userBalance = useSelector((state) => state.userBalance);
  // const menuOpen = props.menuOpen;
  const history = useHistory();
  const [timeZone, setTimeZone] = useState("America/New_York");

  // expand header before login
  const [redeemTooltipOpen, setRedeemTooltipOpen] = useState(false);
  const handleRedeemTooltipOpen = () => {
    setRedeemTooltipOpen(true);
  };
  const handleRedeemTooltipClose = () => {
    setRedeemTooltipOpen(false);
  };

  // expand header before login
  const [expandHeader, setExpandHeader] = useState(false);
  const toggleExpandHeader = () => {
    if (expandHeader === false) {
      setExpandHeader(true);
    } else {
      setExpandHeader(false);
    }
  };

  // sidebar
  const [showSidebar, setShowSidebar] = useState(false);
  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

    // notification
  const [notifications, setNotificactions] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const toggleNotification = () => {
    if (notificationModal === false) {
      setNotificationModal(true);
      getNotifications();
    } else {
      setNotificationModal(false);
    }
  };

  const getNotifications = async () => {
    var response = await api.post("/player/messages-and-notifications", {
      page: 1,
    });
    if (response.status === 200) {
      console.log(response.data.data);
      setNotificactions(response.data.data);
    }
  };

  // clock

  const [currentTime, setCurrentTime] = useState();
  const liveTime = () => {
    setCurrentTime(Date());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      liveTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    // ls.get("language");
    // ls.set("timeZone", "America/New_York");
    var getTime = ls.get("timeZone");
    if (getTime) {
      setTimeZone(getTime);
    }
  }, []);

  //open support sidebar
  const [showSupportSidebar, setShowSupportSidebar] = useState(false);
  useEffect(() => {
    if (showSupportSidebar === true) {
      props.showSupportSidebar(showSupportSidebar);
    }
  }, [showSupportSidebar]);

  //open Buy Modal
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showDeposiModal, setShowDeposiModal] = useState(false);
  const [buyGoldPlanData, setBuyGoldPlanData] = useState({
    planId: "",
    planPrice: "",
    GC: 0,
    SC: 0,
    openWallet: false,
  });
  const callDepositModal = (receiveVal) => {
    console.log("receiveVal", receiveVal);
    setBuyGoldPlanData({
      ...buyGoldPlanData,
      planId: receiveVal.planId,
      planPrice: receiveVal.planPrice,
      openWallet: receiveVal.openWallet,
      GC: receiveVal.GC,
      SC: receiveVal.SC,
    });
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      isFullVerified();
    }
  }, [props.isAuthenticated]);

  const [verified, setVerified] = useState(false);

  const isFullVerified = async () => {
    // var response = await api.get("/id-uploads");
    // if (
    //   response.data.poif &&
    //   response.data.poib &&
    //   response.data.poif.approved == "approved" &&
    //   response.data.poib.approved == "approved" &&
    //   response.data.poa &&
    //   response.data.poa.approved == "approved" &&
    //   response.data.sof &&
    //   response.data.sof.approved == "approved"
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }

    let response = await api.get("/plaid/id-verification/status");
    let verified = response.status === 200 && response.data.status && response.data.status === "success" ? true : false;
    setVerified(verified);
    return verified;
  };

  const redeemSweepCoin = async () => {
    var verified = await isFullVerified();
    if (verified) {
      history.push("/redeem");
    } else {
      history.push("/redeem-rules");
    }
    setShowSidebar(false);
  };

  const makeSweepStakeTooltip = () => {
    var tooltip = (
      <>
        {__(
          "Sweepstake Coins can be used to play sweepstake games for a chance to win more Sweepstake Coins which are redeemable for real prizes. 1 Sweepstake Coin can be redeemed for $1 USD (subject to minimum balance)."
        )}
        <br />
        <br />

        <div>
          {__("Sweepstake Coin Balance")}: SC {userBalance.cash_balance}
        </div>
        <div>
          {__("Available to Redeem")}: SC {userBalance.redeemableAmount ? userBalance.redeemableAmount.toFixed(2) : ""}
        </div>
        <div>
          {__("Unplayed Balance")}: SC{" "}
          {userBalance.redeemableAmount ? (userBalance.cash_balance - userBalance.redeemableAmount).toFixed(2) : ""}
        </div>
      </>
    );
    return tooltip;
  };

  return (
    <>
      <BuyGoldCoins callDepositModal={callDepositModal} showBuyModal={showBuyModal} />
      {/* <SupportSidebar {...props} /> */}

      <section className="headerComponent">
        <div className="headerContent">
          <div className={"logoMenubar " + (props.showLeftSidebar ? "leftSidebarOpened" : "")}>
            <Link className="logo" to="/">
              <Logo {...props} />
              {/* {!props.isMobileDevice && (
                <img
                  src={
                    require("assets/sweeplay/images/common/logo.svg").default
                  }
                />
              )}
              {props.isMobileDevice && (
                <img
                  src={
                    require("assets/sweeplay/images/common/short-logo.png")
                      
                  }
                />
              )} */}
            </Link>
            {/* <VipIcon /> */}
            <Link
              onClick={() => {
                props.setShowLeftSidebar(!props.showLeftSidebar);
              }}
              className="sideMenuBar"
            >
              <AngleRight width="15" height="15" fillColor="#4b486e" />
            </Link>
          </div>
          <div className="highlightedCta">
            <ul>
              <li>
                <Link to="/promotions">
                  <GiftBox fillColor="#4b486e" />
                  <span>{__("Promotions")}</span>
                </Link>
              </li>
              <li>
                <Link to="/loyalty-program">
                  <HeartIcon fillColor="#4b486e" />
                  <span>{__("VIP & Loyalty")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="userCta">
            {!props.isAuthenticated && (
              <div className="nonLoggedIn">
                <ul>
                  <li>
                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      onClick={() => {
                        props.setModalLoginPropsData(true);
                        setTimeout(function () {
                          props.setModalLoginPropsData(false);
                        }, 500);
                      }}
                      className="purpleBtn commonBtn"
                    >
                      {__("Sign In")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      hrefLang={Translator.getSelectedLanguage().code}
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="orengeRedBtn registerBtn commonBtn"
                    >
                      {__("Register")}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {props.isAuthenticated && (
              <div className="loggedIn">
                <div className="walletDepositBlock">
                  <CoinSwitcher className={props.isMobileDevice ? "mobileHeader" : ""} {...props} />
                </div>
                <div className="userActions">
                  <div className="userDropdownMenuBlock">
                    <UserDropDownMenu {...props} />
                  </div>
                  {/* {!props.isMobileDevice && (
                    <div className="settingsBlock">
                      <SettingsMenu {...props} />
                    </div>
                  )} */}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
