import React, { useContext } from "react";
import { useSelector } from "react-redux";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const LoginBonus = (props) => {
  const bonus = useSelector((state) => state.loginBonus);
  const { setLoginBonus } = useContext(AppContext);
  const claimLoginBonus = async () => {
    var response = await api.get("/daily-sc/claim");
    if (response.status == 200) {
      Swal.fire("Congratulations!", "Your daily bonus is claimed", "success");
      setLoginBonus({ sc: 0, gc: 0 });
    } else {
      setLoginBonus({ sc: 0, gc: 0 });
      Swal.fire("Congratulations!", response.data.message, "error");
    }
  };

  return bonus.sc || bonus.gc ? (
    <>
      <div className="claim_login_bonus">
        <Link className="commonBtn purpleBtn" onClick={claimLoginBonus}>
          <div class="text-big">
            <img alt="" src={require("assets/sweeplay/images/coinSwitcher/fun-coin.svg").default} />{" "}
            <div>GC {bonus.gc}</div>
          </div>
          <div class="text">Claim Bonus</div>
        </Link>
      </div>
    </>
  ) : (
    <></>
  );
};

export default LoginBonus;
