import { createSlice } from "@reduxjs/toolkit";

export const loginBonus = createSlice({
  name: "loginBonus",
  initialState: {
    sc: 0,
    gc: 0,
  },
  reducers: {
    updateLoginBonus: (state, action) => {
      state.sc = action.payload.sc;
      state.gc = action.payload.gc;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLoginBonus } = loginBonus.actions;

export default loginBonus.reducer;
