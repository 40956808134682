import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../services/api";

export default function SweepsRules(props) {
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "sweeps_rules",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect( () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <div class="container-xxl">
        <section className="contentPage">
          <div className="pageTitle">
            {pageContent && pageContent.content_data.page_title}
          </div>
          <div className="paragraph">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </div>
        </section>
      </div>
    </>
  );
}
