import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

export default function LanguageSwitcherAccordion(props) {
  const history = useHistory();
  const [languages, setLanguages] = useState([]);
  const selectedLanguage = Translator.getSelectedLanguage();
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      // setLanguages(
      //   response.data.map((val) => {
      //     return { label: val.language, value: val.code };
      //   })
      // );
      setLanguages(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const switchLanguage = (lang) => {
    // var homeUrl = history.createHref({ pathname: "/" });
    // var currentUrl = window.location.href;
    // var newUrl = currentUrl.replace(homeUrl, "/" + lang.code + "/");
    Translator.setSelectedLanguage(lang);
    // window.location.href = newUrl;
    window.location.reload();
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <div className="languageSwitcherAccordion">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2
            className="accordion-header accordionBtnStyle"
            id="flush-headingOne"
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.962"
                  height="12.481"
                  viewBox="33 523.174 24.962 12.481"
                >
                  <path
                    d="M38.932 528.642c-.136-.472-.304-1.295-.304-1.295h-.02s-.167.823-.304 1.295l-.433 1.463h1.486l-.425-1.463Zm18.094-5.468H46.105v12.48h10.92a.934.934 0 0 0 .937-.935v-10.61a.934.934 0 0 0-.936-.935Zm-.936 4.68a.47.47 0 0 1-.468.468h-.445c-.269.92-.846 1.849-1.665 2.727.327.25.667.487 1.018.702a.47.47 0 0 1 .16.631l-.309.543a.47.47 0 0 1-.65.167 13.57 13.57 0 0 1-1.382-.97c-.425.338-.885.666-1.38.97a.47.47 0 0 1-.652-.167l-.308-.543a.468.468 0 0 1 .164-.631c.363-.223.702-.457 1.018-.702a9.05 9.05 0 0 1-.82-1.003.467.467 0 0 1 .145-.667l.254-.152.284-.168a.472.472 0 0 1 .625.133c.195.273.42.546.678.815.527-.554.928-1.127 1.17-1.685H49.07a.47.47 0 0 1-.468-.468v-.624a.47.47 0 0 1 .468-.468h2.496v-.624a.47.47 0 0 1 .468-.468h.625a.47.47 0 0 1 .468.468v.624h2.496a.47.47 0 0 1 .468.468v.624ZM33 524.11v10.609c0 .518.417.936.936.936h10.92v-12.481h-10.92a.934.934 0 0 0-.936.936Zm2.297 8.428 2.243-6.595a.466.466 0 0 1 .445-.316h1.267c.2 0 .379.129.445.316l2.242 6.595a.47.47 0 0 1-.444.62h-.893a.468.468 0 0 1-.449-.335l-.366-1.244h-2.348l-.355 1.24a.472.472 0 0 1-.449.34h-.893a.471.471 0 0 1-.445-.62Z"
                    fill="#b8c0da"
                    fill-rule="evenodd"
                    data-name="Icon awesome-language"
                  />
                </svg>
              </div>
              <div className="text">
                {__("Language:")} {selectedLanguage.language}
              </div>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <ul className="languageItemLists">
              {languages.length > 0 &&
                languages.map((value, index) => (
                  <li
                    onClick={() => switchLanguage(value)}
                    className={
                      selectedLanguage.code == value.code ? "selected" : ""
                    }
                  >
                    <label for={value.code} className="text fullName">
                      {value.alias ? value.alias : value.language}
                    </label>

                    <label for={value.code} className="text shortName">
                      {value.code}
                    </label>
                    <input
                      type="radio"
                      id={value.code}
                      defaultChecked={
                        selectedLanguage.code == value.code ? true : false
                      }
                      name="lang"
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
