import React from "react";
import { __ } from "services/Translator";
import { Button } from "react-bootstrap";
export default function Pagination(props) {
  const landingType = props.landingType;
  const data = props.data;
  // const __ = (text) => text;
  const currentPage =
    props.data &&
    props.data.length &&
    props.data.filter((item) => item.type === "current_page").length
      ? props.data.filter((item) => item.type === "current_page")[0].page
      : 1;

  const lastPage =
    props.data && props.data.length
      ? props.data[props.data.length - 1].page
      : 1;

  const onPageChange = (page) => {
    if (props.onPageChange && page) {
      props.onPageChange(page);
    }
  };

  const incrementPage = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  if (!data || currentPage >= lastPage) {
    return null;
  }

  return (
    <>
      <div className="container d-flex justify-content-center">
        <Button
          onClick={incrementPage}
          className={"commonBtn orengeRedBtn noBorder"}
        >
          {__("Load More")}
        </Button>
      </div>
    </>
  );
}
