import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

import { Link } from "react-router-dom";

export default function Tournaments() {
  const [openDetails, setOpenDetails] = useState(false);
  const [raceTab, setRaceTab] = useState("In Progress");
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  return (
    <>
      <div className="races_page">
        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="winner_toggle margin_auto">
              <div
                onClick={() => {
                  setRaceTab("In Progress");
                }}
                className={
                  raceTab === "In Progress" ? "toggle_btn active" : "toggle_btn"
                }
              >
                In Progress
              </div>
              <div
                onClick={() => {
                  setRaceTab("Ended");
                }}
                className={
                  raceTab === "Ended" ? "toggle_btn active" : "toggle_btn"
                }
              >
                Ended
              </div>
            </div>
            <br />
            <div className="race_list">
              {raceTab === "In Progress" && (
                <>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          ENDING IN
                        </div>
                        <div className="time text-center">01 : 22 : 56</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          ENDING IN
                        </div>
                        <div className="time text-center">01 : 22 : 56</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          ENDING IN
                        </div>
                        <div className="time text-center">01 : 22 : 56</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          ENDING IN
                        </div>
                        <div className="time text-center">01 : 22 : 56</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          ENDING IN
                        </div>
                        <div className="time text-center">01 : 22 : 56</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              )}
              {raceTab === "Ended" && (
                <>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          THE RACE HAS
                        </div>
                        <div className="time text-center">Finished</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          THE RACE HAS
                        </div>
                        <div className="time text-center">Finished</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="race_schedule dark_white text-center d-none d-md-block">
                      JANUARY 14TH 2022, 11:59:00 AM
                    </div>
                    <br />
                    <div className="race_dot d-none d-md-block"></div>
                    <div className="race_border d-none d-md-block"></div>
                    <div className="race_content">
                      <div className="more_btn">
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                          }}
                          className="details_btn"
                        >
                          <svg
                            name="Checkmark"
                            width="15"
                            height="15"
                            className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                          </svg>
                        </Button>
                      </div>
                      <div className="race_banner">
                        <img
                          src={
                            require("assets/images/banners/race1-banner.jpg")
                              
                          }
                        />
                      </div>
                      <div className="race_details">
                        <div className="paragraph text-white uppercase text-center no_margin">
                          THE RACE HAS
                        </div>
                        <div className="time text-center">Finished</div>
                        <div className="bonuses">
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              SPINS
                            </div>
                            <div className="bonus_value">60</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              MIN BET
                            </div>
                            <div className="bonus_value">0.0114 mɃ</div>
                          </div>
                          <div className="single_item">
                            <div className="paragraph text-white uppercase text-center no_margin">
                              DURATION
                            </div>
                            <div className="bonus_value">3 days</div>
                          </div>
                        </div>
                        <br />

                        <div className="view_leadboard terms_btn">
                          <Link
                            to="#"
                            onClick={() => {
                              setShowLeaderBoard(true);
                            }}
                            className="common_button small no_margin"
                          >
                            view leaderboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={openDetails}>
        <Modal.Body className="tournaments_modal">
          <div className="close_button">
            <Button
              variant="danger close_btn"
              onClick={() => {
                setOpenDetails(false);
              }}
            >
              Close
            </Button>
          </div>
          <h3 className="dark_blue">
            <strong>iCryptoGaming Races: How it Works</strong>
          </h3>
          <br />
          <div className="paragraph text-white">
            1. Every 3 hours, you can enter the iCryptoGaming Races slots
            tournament and get the first spot based on your Multiplier score.
          </div>
          <div className="paragraph text-white">
            2. Only Real Money gameplay on slots count towards the tournament.
          </div>
          <div className="paragraph text-white">
            3. Only cryptocurrencies count towards the tournament.
          </div>
          <div className="paragraph text-white">
            4. The leaderboard is updated in real time.
          </div>
          <div className="paragraph text-white">
            5. Points are generated based on the Multiplier score. Let’s say you
            bet 0.3 mBTC and win 3 mBTC, that’s a 10xMultiplier. The higher your
            multiplier score, the bigger your chance of getting on the
            leaderboard.
          </div>
          <div className="paragraph text-white">
            6. Money prizes offered as part of the Tournament have a 0x wager
            requirement.
          </div>
          <div className="paragraph text-white">
            7. Prizes are automatically added to the player account at the end
            of each tournament round.
          </div>
          <div className="paragraph text-white">
            8. Player’s full nickname will be displayed on the leaderboard. If
            you haven’t set up a nickname for your account yet, you can do this
            on your profile information page, or contact support.
          </div>
          <div className="paragraph text-white">
            9. By setting a nickname and participating in the tournament, you
            agree to have your nickname publicly displayed.
          </div>
          <div className="paragraph text-white">
            10. General Terms & Conditions and Bonus Terms & Conditions apply.
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="xl" show={showLeaderBoard}>
        <Modal.Body className="tournaments_modal">
          <div className="close_button">
            <Button
              variant="danger close_btn"
              onClick={() => {
                setShowLeaderBoard(false);
              }}
            >
              Close
            </Button>
          </div>
          <br />
          <br />
          <div className="leaderboard_table">
            <Table>
              <thead>
                <tr>
                  <td>#</td>
                  <td>Player</td>
                  <td>Points</td>
                  <td>Reward</td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>
                    <img
                      src={require("assets/images/games/games1.png")}
                    />
                    Brandon
                  </td>
                  <td>324.750</td>
                  <td>2.5 mBTC</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
