import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import auth from "services/auth";
// import ls from "local-storage";
// import AppContext from "contexts/AppContext";
import api from "services/api";
// import emailValidate from "services/emailValidate";
import GameItem from "elements/GameElements/GameItem";
import { __ } from "services/Translator";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import GameMenu from "elements/GameElements/GameMenu";
//New structured import
import { AngleRight, AngleLeft } from "components/Icons";
export default function GameSlider(props) {
  // const menuOpen = props.menuOpen;
  const location = useLocation();
  const [categoryName, setCategoryName] = useState(props.categoryName);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [gameListsData, setGameListsData] = useState([]);
  const [popularSlots, setPopularSlots] = useState([]);
  const [tableGames, setTableGames] = useState([]);
  const category = props.category;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.perPage);

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [category],
      specific_games: props.specificGames,
    });

    if (response.status === 200 && response.data) {
      if (category == "video_slots") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.video_slots.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (category == "table_games") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.table_games.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (category == "video_poker") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.video_poker.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (category == "live_casino") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.live_casino.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (category == "new") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.new.gamelist
        );

        setGameListsData(mergedGameList);
      } else if (category == "popular") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.popular.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (category == "jackpot") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.jackpot.gamelist
        );
        setGameListsData(mergedGameList);
      }
    }
  };
  const gameTitles = {
    slots: __("Slots"),
    blackjack: __("Blackjack"),
    "video-poker": __("Video Poker"),
    "live-dealer": __("Live Dealer"),
    "live-casino": __("Live Casino"),
    "bonus-buy": __("Bonus Buys"),
    megaways: __("Megaways"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    table_games: __("Table Games"),
    popular: __("Popular"),
    new: __("New"),
    search_results: __("Search Results"),
    "provably-fair": __("Provably Fair"),
  };
  useEffect( () => {
    if (category) {
      getGames();
    }
  }, [category, page]);

  const loadMoreGamesAction = (prm) => {
    setPage(page + 1);
    setPerPage(24);
  };

  const gameTypeAPISlugsReverse = {
    new: "new",
    video_slots: "slots",
    live_casino: "live-casino",
    table_games: "table-games",
    popular: "popular",
    jackpot: "jackpot",
  };

  // games settings
  const gameNextLoad = () => {
    sliderRef.current.slickNext();
  };

  const gamePrevLoad = () => {
    sliderRef.current.slickPrev();
  };

  const gamesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // className: "center",
          // centerMode: true,
        },
      },
    ],
  };

  const sliderRef = useRef();
  return (
    <section className="gameSlider">
      <div className=" game_container padding_top_bottom_30">
        <div className="gameSliderHeading">
          {/* <div className="headline_text">{props.categoryName}</div> */}
          <div className="gameAction">
            {props.title == true && (
              <div className="headline_text">
                {gameTitles[props.category] ?? ""}
              </div>
            )}

            {props.menuBar != false && (
              <GameMenu callFromSlider={true} {...props} />
            )}

            <ul className="gameMenu d-none">
              <li>
                <Link
                  className={location.pathname == "/games/all" ? "active" : ""}
                  to="/games/all"
                >
                  <svg className="svg_icon">
                    <use xlinkHref={"/icons.svg#icon-provider-slots"} />
                  </svg>
                  <span>{__("ALl Games")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname == "/games/slots" ? "active" : ""
                  }
                  to="/games/slots"
                >
                  <svg className="svg_icon">
                    <use xlinkHref={"/icons.svg#icon-provider-slots"} />
                  </svg>
                  <span>{__("Slot")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname == "/games/table-games" ? "active" : ""
                  }
                  to="/games/table-games"
                >
                  <svg className="svg_icon">
                    <use xlinkHref={"/icons.svg#icon-fire"} />
                  </svg>
                  <span>{__("Table Games")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname == "/games/live-casino" ? "active" : ""
                  }
                  to="/games/live-casino"
                >
                  <svg className="svg_icon">
                    <use xlinkHref={"/icons.svg#icon-provider-live-dealers"} />
                  </svg>
                  <span>{__("Live Casino")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className={location.pathname == "/games/new" ? "active" : ""}
                  to="/games/new"
                >
                  <svg className="svg_icon">
                    <use xlinkHref={"/icons.svg#icon-fire"} />
                  </svg>
                  <span>{__("New Games")}</span>
                </Link>
              </li>
            </ul>

            <div className="slideAction">
              <Link to={"/games/all"} className="viewAll commonBtn purpleBtn">
                {__("View All")}
              </Link>
              <Button onClick={gamePrevLoad} className="action_btn">
                <AngleLeft width="12" height="12" fillColor="#4b486e" />
              </Button>
              <Button onClick={gameNextLoad} className="action_btn">
                <AngleRight width="12" height="12" fillColor="#4b486e" />
              </Button>
            </div>
          </div>
        </div>

        <Slider ref={sliderRef} {...gamesSettings}>
          {gameListsData.map((game, index) => (
            <GameItem game={game} isAuthenticated={props.isAuthenticated} />
          ))}
        </Slider>
      </div>
    </section>
  );
}
