import React, { useState, useEffect, useContext } from "react";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
// import Header from "elements/Header";
import Footer from "elements/Footer";
import LoginBonus from "elements/LoginBonus";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import api from "services/api";
import RewardSystem from "elements/RewardSystem";
import Swal from "sweetalert2";

import { isMobile, isMobileByViewPort, cauculateLandScape } from "services/isMobile";
// import { useIdleTimer } from "react-idle-timer";

// import SuppotrSideBar from "elements/SuppotrSideBar";
import IdleActivity from "elements/IdleActivity";

//Updated structure start's here
import SecurityPopup from "components/SecurityPopup";
import LeftSidebarShortLong from "components/LeftSidebarShortLong";
import RightSidebarShortLong from "components/RightSidebarShortLong";
import StickyFooterMenu from "components/StickyFooterMenu";
import GeoRestriction from "elements/GeoRestriction";
import ScrollToTop from "elements/ScrollToTop";
import Loader from "components/Loader";
import Header from "components/Header";
import LoginReg, { loginData } from "components/LoginReg";
import LiveChat from "elements/LiveChat";
import { useSelector, useDispatch } from "react-redux";
import { updateUserBalance } from "stores/balance/userBalance";

const FullLayout = ({ children, ...rest }) => {
  const [openSupportSideBar, setOpenSupportSideBar] = useState();
  const showSupportSidebar = (receiveVal) => {
    setOpenSupportSideBar(receiveVal);
    setTimeout(function () {
      setOpenSupportSideBar(false);
    }, 500);
  };
  const dispatch = useDispatch();

  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  const [modalLoginPropsData, setModalLoginPropsData] = useState(false);

  const [spinner, setSpinner] = useState(false);
  const [dummySpinner, setDummySpinner] = useState(false);

  const location = useLocation();
  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);

  const showAlert = (title, message, isError, callback) => {
    Swal.fire({
      className: "dark",
      title: title,
      text: message,
      timer: 70000, // Set the time duration in milliseconds
      showConfirmButton: true, // Hide the "OK" button
      icon: isError ? "warning" : "success",
      customClass: {
        popup: "dark-theme-popup",
        title: "dark-theme-title",
        content: "dark-theme-content",
        confirmButton: "dark-theme-confirm-button",
      },
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());
  const [userDetails, setUserDetails] = useState(auth.getUser);

  //Get user balance functionality

  const { getUserBalance } = useContext(AppContext);

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    // console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  const [notificationCount, setNotifictionCount] = useState(0);
  const getNotificationsCount = async (ev) => {
    var response = await api.get("/player/messages-and-notifications-count");
    if (response.status === 200) {
      setNotifictionCount(response.data);
    }
  };

  useEffect(() => {
    // alert(window.innerWidth);
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());
    if (!isMobile() && window.innerWidth > 1200) {
      setShowLeftSidebar(true);
    }
    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getNotificationsCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
        getNotificationsCount();
      }
    }, 10000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  useEffect(() => {
    setDummySpinner(true);
    setTimeout(() => {
      setDummySpinner(false);
    }, 500);
  }, [location.pathname]);

  const [displayedModal, setDisplayedModal] = useState(null);

  const [breadcrumb, setBreadcrumb] = useState([]);

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    // console.log(response);
    if (response.status === 200) {
      dispatch(updateUserBalance(response.data));
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);
  const [showBuyModalG, setShowBuyModalG] = useState(false);

  const [searchStr, setSearchStr] = useState(""); //Used in Header.js and Games.js
  const [providers, setProviders] = useState([]); //Used in Header.js and Games.js
  const [sortStr, setSortStr] = useState(null); //Used in Header.js and Games.js
  const [selectedProviders, setSelectedProviders] = useState([]); //Used in Header.js and Games.js
  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  useEffect(() => {
    getProviders();
  }, []);

  const [categoryName, setCategoryName] = useState("Slots");
  //updated structure
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [animationStarts, setAnimationStarts] = useState(false);
  const passableParams = {
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setIsAuthenticated,
    setSpinner,
    userDetails,
    notificationCount,
    accountProgress,
    getAccountProgress,
    displayedModal,
    setDisplayedModal,
    breadcrumb,
    setBreadcrumb,
    setUsersWallet,
    openWalletModalTab,
    setOpenWalletModalTab,
    searchStr,
    setSearchStr,
    providers,
    setProviders,
    selectedProviders,
    sortStr,
    setSortStr,
    setSelectedProviders,
    getProviders,
    showLeftSidebar,
    setShowLeftSidebar,
    setCategoryName,
    categoryName,
    modalSignUpPropsData,
    setModalSignUpPropsData,
    modalLoginPropsData,
    setModalLoginPropsData,
    showRightSidebar,
    setShowRightSidebar,
    supportSidebarOpen,
    setSupportSidebarOpen,
    isChatVisible,
    setIsChatVisible,
    setShowBuyModalG,
    showBuyModalG,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setInPlay(playPath.includes("play"));
    setIsMobileDevice(isMobile());
  }, [playPath]);

  return (
    <>
      {spinner && <Loader />}
      <SecurityPopup {...passableParams} />
      <LoginReg {...passableParams} />
      {(isMobileDevice && inPlay) || <Header {...passableParams} />}
      <div className={"mainContainer " + (isChatVisible && isAuthenticated ? " chat_open " : "")}>
        <LeftSidebarShortLong {...passableParams} />
        <div id="mainContent" className={"mainContent " + (passableParams.showLeftSidebar ? "sidebarOpend" : "")}>
          <main className={animationStarts ? " slideUpAnimation ms400" : " "}>{childrenWithProps}</main>
          {(isMobileDevice && inPlay) || <Footer {...passableParams} />}
        </div>
      </div>
      <LiveChat {...passableParams} />
      {isMobileDevice && (!inPlay || !isLandscape) && <StickyFooterMenu {...passableParams} />}
      <GeoRestriction {...passableParams} />
      <LoginBonus />
      <RewardSystem {...passableParams} />
      <ScrollToTop />
    </>
  );
};

export default FullLayout;
