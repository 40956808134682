import React, { useState, useEffect } from "react";

const RemainintTime = ({ timestamp }) => {
  const formatTimeStamp = (timestamp) => {
    var ret = "";
    ret += parseInt(timestamp / 3600) + " : ";
    ret +=
      parseInt((timestamp % 3600) / 60)
        .toString()
        .padStart(2, "0") + " : ";
    ret += parseInt(timestamp % 60)
      .toString()
      .padStart(2, "0");
    return ret;
  };

  var latestTimeStamp = timestamp;

  const [formatted, setFormatted] = useState(formatTimeStamp(timestamp));

  useEffect(() => {
    var interval = window.setInterval(() => {
      latestTimeStamp = latestTimeStamp - 1;
      if (latestTimeStamp <= 0) {
        window.clearInterval(interval);
        return 0;
      }
      setFormatted(formatTimeStamp(latestTimeStamp));
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, [timestamp]);
  return <>{formatted}</>;
};

export default RemainintTime;
