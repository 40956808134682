import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import SupportSidebar from "../elements/SupportSidebar";
import Logo from "elements/Logo";

const Footer = (props) => {
  // const menuOpen = props.menuOpen;
  const [showSupportSidebar, setShowSupportSidebar] = useState(false);
  useEffect(() => {
    if (showSupportSidebar === true) {
      props.showSupportSidebar(showSupportSidebar);
    }
  }, [showSupportSidebar]);

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <footer>
        <section class="footer">
          <div class="container">
            <div className="footerLink--box">
              <div className="row">
                <div class="brands our-Brands col-12 col-lg-6 col-md-12">
                  {/* <h4 className="section_title">Description</h4> */}
                  <div className="footer_18_plus_icon">
                    <svg width="40" height="40">
                      <use
                        xlinkHref={
                          require("assets/sweeplay/images/icons/18plus.svg").default
                             + "#default"
                        }
                      />
                    </svg>
                  </div>
                  <br />
                  <br />
                  <p>
                    Sweeplay Casino Social Gaming Platform is a play-for-fun
                    website intended for amusement purposes only. Sweeplay
                    Casino Social Gaming Platform does not offer “real-money
                    gambling” or opportunity to win real money based on a
                    gameplay. Sweeplay Casino Social Gaming Platform is only
                    open to Eligible Participants, who are at least eighteen
                    (18) years old or the age of majority in their jurisdiction
                    (whichever occurs later) at the time of entry. NO PURCHASE
                    NECESSARY to enter Sweepstakes. SWEEPSTAKES ARE VOID WHERE
                    PROHIBITED BY LAW. For detailed rules, see{" "}
                    <Link to="/sweeps-rules">Sweeps Rules</Link>
                  </p>

                  {/* <a
                    className="footer_credit_logo"
                    href="https://www.patriotbankcard.com"
                  >
                    <img
                      src="https://www.patriotbankcard.com/cclogos/major-credit-card-logos-png-5.png"
                      alt="logo"
                    />
                  </a> */}

                  {/* <img width="170" src={require("assets/images/payment_methods/PayPal-Logo.png")} /> */}
                </div>
                <div class="quicklinks col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Quick Links</h4>
                  <ul>
                    <li className="d-none">
                      <Link target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Fan Wall
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/sweeps-rules">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Sweeps Rules
                      </Link>
                    </li>
                    <li>
                      <Link to="/responsible-social-gameplay-policy">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Responsible Social Gameplay
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          props.setIsChatVisible(false);
                          props.setSupportSidebarOpen(true);
                          props.setShowRightSidebar(true);
                        }}
                      >
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Contact Us
                      </Link>
                    </li>
                    {/* <li>
                      <Link target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Privacy settings
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/promotions">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Promotions
                      </Link>
                    </li>
                    <li className="d-none">
                      <Link to="/tournaments">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Tournaments
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="brands socials-Link col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Socials Links</h4>
                  <ul>
                    <li>
                      <a target="_blank" href="#">
                        <i class="fab fa-facebook-f icon"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="#">
                        <i class="fab fa-instagram icon"></i>
                      </a>
                    </li>
                    <li className="d-none">
                      <a href="#">
                        <i class="fab fa-linkedin-in icon"></i>
                      </a>
                    </li>
                    <li className="d-none">
                      <a href="#">
                        <i class="fab fa-google-plus-g icon"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="Footer_logo">
                    <Link to="/" className="logo">
                      <img
                        alt=""
                        src={
                          require("../assets/sweeplay/images/logos/logo.svg").default
                            
                        }
                      />
                      {<span>Sweeplay</span>}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="clearfix"></div> */}
            <div class="footer_description">
              <big>Copyright © {year} Sweeplay Casino.</big>
              <br />
              Sweeplay Casino Casino is owned and operated by Sweeplay LLC.
              <br />
              The registered address of Sweeplay LLC is Social Casino Street 1,
              XX5401, Fictional State, Country Name.
              <br />
              <br />
              <big>
                <b>Postal Alternative Method of Entry</b>
              </big>
              <br />
              Receive free Sweepstake Coins by obtaining a Postal Request Code
              and sending a handwritten request that meets the requirements
              specified in our Sweeps Rules to: Sweeplay LLC, Social Casino
              Street 1, XX5401, Fictional State, Country Name. For more details
              see our <Link to="/sweeps-rules">Sweeps Rules</Link>.
            </div>
          </div>
        </section>
      </footer>
      {/* <SupportSidebar {...props} /> */}
    </>
  );
};

export default Footer;
