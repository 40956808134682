import React, { useState, useEffect, useRef } from "react";
import { __ } from "services/Translator";
import { Collapse, Dropdown } from "react-bootstrap";
import api from "services/api";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import GamesFilter from "./GamesFilter";
import GameCategoryDropdown from "elements/GameElements/GameCategoryDropdown";

export default function GamesMenu(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const gamesCategory = params.category;
  return (
    <>
      <div
        className={
          "gameFilterAreas " + (props.callFromSlider ? "callFromSlider" : "")
        }
      >
        <ul className="gameMenu">
          <li>
            <Link
              className={location.pathname == "/games/all" ? "active" : ""}
              to="/games/all"
            >
              <div className="icon logoIcon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/logos/single-logo.svg").default
                      
                  }
                />
              </div>
              <div className="text">{__("All Games")}</div>
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname == "/games/slots" ? "active" : ""}
              to="/games/slots"
            >
              <div className="icon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/menuIcons/slots.svg").default
                      
                  }
                />
              </div>
              <div className="text">{__("Slots")}</div>
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname == "/games/table-games" ? "active" : ""
              }
              to="/games/table-games"
            >
              <div className="icon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/menuIcons/table-games.svg").default
                      
                  }
                />
              </div>
              <div className="text">{__("Table Games")}</div>
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname == "/games/popular" ? "active" : ""}
              to="/games/popular"
            >
              <div className="icon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/menuIcons/popular.svg").default
                      
                  }
                />
              </div>
              <div className="text">{__("Popular")}</div>
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname == "/games/bonus-buy" ? "active" : ""
              }
              to="/games/bonus-buy"
            >
              <div className="icon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/menuIcons/bonus.svg").default
                      
                  }
                />
              </div>
              <div className="text">{__("Bonus Buy")}</div>
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname == "/games/new" ? "active" : ""}
              to="/games/new"
            >
              <div className="icon">
                <img
                  alt=""
                  src={
                    require("assets/sweeplay/images/menuIcons/new.svg").default
                  }
                />
              </div>
              <div className="text">{__("New")}</div>
            </Link>
          </li>
        </ul>

        {/* {props.isMobileDevice && <GameCategoryDropdown {...props} />} */}
        {!props.callFromSlider && !props.isMobileDevice && (
          <GamesFilter {...props} />
        )}
      </div>
    </>
  );
}
