import React, { useState, useEffect } from "react";
import api from "services/api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { __ } from "services/Translator";

const HomeBanner = (props) => {
  // const menuOpen = props.menuOpen;
  const [searchVal, setSearchVal] = useState("");

  const updateInput = (ev) => {
    var target = ev.target;
    // var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };

  const [homeBanners, setHomeBanners] = useState([]);
  const getHomeBanners = async () => {
    var response = await api.get("/banners");
    if (response.status === 200) {
      setHomeBanners(response.data);
    }
  };

  useEffect(() => {
    getHomeBanners();
  }, []);

  const formatSubtitle = (text) => {
    try {
      var textParts = text.split("**");
      return (
        <>
          {textParts.map((part, index) => {
            if (index % 2 == 1) {
              return <span>{part}</span>;
            } else {
              return part;
            }
          })}
        </>
      );
    } catch (ex) {
      return text;
    }
  };

  return (
    <>
      <section className="header_banner_area">
        <div className="banner_v1">
          {!props.isMobileDevice && (
            <div className="row">
              <div className="col-12 col-md-7 col-lg-8">
                <div className="single_item">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/main-banner-one.webp")}
                      alt="SweePlay Casino "
                      className="bigBanner"
                    />
                    <img
                      src={require("assets/sweeplay/images/banners/main-banner-one-tab.jpg")}
                      className="midBanner"
                      alt="SweePlay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="smallMsg">{__("More fun and excitement plus")}</div>
                    <div className="banner_headline">{__("CASH PRIZES")}</div>
                    {/* <div className="banner_headline_colored">
                        {__("100% Welcome Offer 1BTC")}
                      </div> */}
                    {/* <div className="banner_description">
                        {__("T&C Applies, read bonus terms for more details")}
                      </div> */}
                    <br />
                    <br />
                    <br />
                    <div className="banner_link">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn customeSize">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn customeSize"
                          >
                            {__("Play Now!")}
                          </Link>
                          <ul className="socialPlatform d-none">
                            <li>
                              <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path d="M19.545 15.025v-2.421h2.422v-1.815h-2.422V8.368H17.73v2.421h-2.421v1.815h2.421v2.421zM8.052 8.302c1.133 0 1.896.484 2.33.896l1.722-1.67c-1.048-.98-2.415-1.58-4.052-1.58C4.714 5.947 2 8.659 2 12s2.714 6.053 6.052 6.053c3.493 0 5.812-2.457 5.812-5.913 0-.503-.064-.86-.153-1.24H8.049v2.256h3.332c-.158.951-1.011 2.556-3.329 2.556-2.003 0-3.637-1.665-3.637-3.704 0-2.042 1.634-3.706 3.637-3.706z"></path>
                                </g>
                              </svg>
                            </li>
                            <li>
                              <svg
                                viewBox="-5 0 20 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>facebook [#176]</title> <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    {" "}
                                    <g
                                      id="Dribbble-Light-Preview"
                                      transform="translate(-385.000000, -7399.000000)"
                                      fill="#000000"
                                    >
                                      {" "}
                                      <g id="icons" transform="translate(56.000000, 160.000000)">
                                        {" "}
                                        <path
                                          d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                          id="facebook-[#176]"
                                        >
                                          {" "}
                                        </path>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li>
                              <svg
                                viewBox="0 -3 20 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>youtube [#168]</title> <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    {" "}
                                    <g
                                      id="Dribbble-Light-Preview"
                                      transform="translate(-300.000000, -7442.000000)"
                                      fill="#000000"
                                    >
                                      {" "}
                                      <g id="icons" transform="translate(56.000000, 160.000000)">
                                        {" "}
                                        <path
                                          d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289"
                                          id="youtube-[#168]"
                                        >
                                          {" "}
                                        </path>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li>
                              <svg
                                fill="#000000"
                                viewBox="0 0 32 32"
                                id="Camada_1"
                                version="1.1"
                                xml="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M22.3,8.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C23.7,9,23.1,8.4,22.3,8.4z"></path>{" "}
                                    <path d="M16,10.2c-3.3,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9s5.9-2.7,5.9-5.9S19.3,10.2,16,10.2z M16,19.9c-2.1,0-3.8-1.7-3.8-3.8 c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8C19.8,18.2,18.1,19.9,16,19.9z"></path>{" "}
                                    <path d="M20.8,4h-9.5C7.2,4,4,7.2,4,11.2v9.5c0,4,3.2,7.2,7.2,7.2h9.5c4,0,7.2-3.2,7.2-7.2v-9.5C28,7.2,24.8,4,20.8,4z M25.7,20.8 c0,2.7-2.2,5-5,5h-9.5c-2.7,0-5-2.2-5-5v-9.5c0-2.7,2.2-5,5-5h9.5c2.7,0,5,2.2,5,5V20.8z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li>
                              <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z"
                                    fill="#000000"
                                  ></path>{" "}
                                </g>
                              </svg>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-4">
                <div className="single_item ">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/small-banner-one.webp")}
                      alt="SweePlay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="banner_headline small">{__("Over 1.000 games at Sweeplay")}</div>

                    <div className="banner_link ">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn"
                          >
                            {__("Become a VIP")}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "15px" }} className="single_item small">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/small-banner-two.webp")}
                      alt="SweePlay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="banner_headline small">{__("Rank Up to higher VIP Levels ")}</div>

                    <div className="banner_link small">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn"
                          >
                            {__("Become a VIP")}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.isMobileDevice && (
            <>
              <Slider {...settings}>
                <div className="single_item">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/main-banner-one.jpg")}
                      alt="Sweeplay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="smallMsg">{__("More fun and excitement plus")}</div>
                    <div className="banner_headline">{__("CASH PRIZES")}</div>
                    {/* <div className="banner_headline_colored">
                        {__("100% Welcome Offer 1BTC")}
                      </div> */}
                    {/* <div className="banner_description">
                        {__("T&C Applies, read bonus terms for more details")}
                      </div> */}

                    <div className="banner_link">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn customeSize">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn customeSize"
                          >
                            {__("Play Now!")}
                          </Link>
                          <ul className="socialPlatform d-none">
                            <li className="googleIAction">
                              <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path d="M19.545 15.025v-2.421h2.422v-1.815h-2.422V8.368H17.73v2.421h-2.421v1.815h2.421v2.421zM8.052 8.302c1.133 0 1.896.484 2.33.896l1.722-1.67c-1.048-.98-2.415-1.58-4.052-1.58C4.714 5.947 2 8.659 2 12s2.714 6.053 6.052 6.053c3.493 0 5.812-2.457 5.812-5.913 0-.503-.064-.86-.153-1.24H8.049v2.256h3.332c-.158.951-1.011 2.556-3.329 2.556-2.003 0-3.637-1.665-3.637-3.704 0-2.042 1.634-3.706 3.637-3.706z"></path>
                                </g>
                              </svg>
                            </li>
                            <li className="facebookAction">
                              <svg
                                viewBox="-5 0 20 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>facebook [#176]</title> <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    {" "}
                                    <g
                                      id="Dribbble-Light-Preview"
                                      transform="translate(-385.000000, -7399.000000)"
                                      fill="#000000"
                                    >
                                      {" "}
                                      <g id="icons" transform="translate(56.000000, 160.000000)">
                                        {" "}
                                        <path
                                          d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                          id="facebook-[#176]"
                                        >
                                          {" "}
                                        </path>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li className="youTubeAction">
                              <svg
                                viewBox="0 -3 20 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <title>youtube [#168]</title> <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    {" "}
                                    <g
                                      id="Dribbble-Light-Preview"
                                      transform="translate(-300.000000, -7442.000000)"
                                      fill="#000000"
                                    >
                                      {" "}
                                      <g id="icons" transform="translate(56.000000, 160.000000)">
                                        {" "}
                                        <path
                                          d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289"
                                          id="youtube-[#168]"
                                        >
                                          {" "}
                                        </path>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li className="instagramAction">
                              <svg
                                fill="#000000"
                                viewBox="0 0 32 32"
                                id="Camada_1"
                                version="1.1"
                                xml="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M22.3,8.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C23.7,9,23.1,8.4,22.3,8.4z"></path>{" "}
                                    <path d="M16,10.2c-3.3,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9s5.9-2.7,5.9-5.9S19.3,10.2,16,10.2z M16,19.9c-2.1,0-3.8-1.7-3.8-3.8 c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8C19.8,18.2,18.1,19.9,16,19.9z"></path>{" "}
                                    <path d="M20.8,4h-9.5C7.2,4,4,7.2,4,11.2v9.5c0,4,3.2,7.2,7.2,7.2h9.5c4,0,7.2-3.2,7.2-7.2v-9.5C28,7.2,24.8,4,20.8,4z M25.7,20.8 c0,2.7-2.2,5-5,5h-9.5c-2.7,0-5-2.2-5-5v-9.5c0-2.7,2.2-5,5-5h9.5c2.7,0,5,2.2,5,5V20.8z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </li>
                            <li className="telegramAction">
                              <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z"
                                    fill="#000000"
                                  ></path>{" "}
                                </g>
                              </svg>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="single_item ">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/small-banner-one.jpg")}
                      alt="SweePlay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="banner_headline small">{__("Over 1.000 games at Sweeplay")}</div>

                    <div className="banner_link ">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn"
                          >
                            {__("Become a VIP")}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="single_item ">
                  <div className="banner_img">
                    <img
                      src={require("assets/sweeplay/images/banners/small-banner-two.jpg")}
                      alt="SweePlay Casino "
                    />
                  </div>

                  <div className="banner_text_section">
                    <div className="banner_headline small">{__("Rank Up to higher VIP Levels ")}</div>

                    <div className="banner_link small">
                      {props.isAuthenticated ? (
                        <Link to="/games/slots" className="commonBtn bannerBtn">
                          {__("Play Now!")}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to=""
                            onClick={() => {
                              props.setModalSignUpPropsData(true);
                              setTimeout(function () {
                                props.setModalSignUpPropsData(false);
                              }, 500);
                            }}
                            className="commonBtn bannerBtn"
                          >
                            {__("Become a VIP")}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Slider>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
