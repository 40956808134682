import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Translator, { __ } from "services/Translator";
import api from "services/api";
import { Accordion } from "react-bootstrap";

export default function StepRightUp(props) {
  const stepUpDesc = [
    {
      paragraph:
        "Craving some casino-style fun without spending a dime? Sweeplay has you covered! Picture this: a treasure trove of slots and jackpots ready for you to explore—all free, and no downloads required. You can jump into the action from any device, anytime, anywhere.",
    },
    {
      paragraph:
        "Starting with Sweeplay is super simple. Just set up your free account, and you're off! We've got a massive collection of games, with new ones popping up almost every week. Whether you're a fan of Blackjack, Roulette, or our enchanting unicorn slots, there's always something exciting waiting for you.",
    },
    {
      paragraph:
        "At Sweeplay, we pride ourselves on being the go-to destination for free online casino-style games. Thousands of players enjoy our games every week, and we’re always eager to welcome more. Our community is friendly and lively, making it the perfect place to share the fun.",
    },
    {
      paragraph:
        "Need help? Our Support Team is available 24/7 to assist you with any questions. Just click the 'contact' button at the top of the page to reach out.",
    },
    {
      paragraph:
        "Why wait? Join Sweeplay today and dive into a world of free casino-style games. Fun is just a click away!",
    },
  ];

  const [visibleTextCount, setVisibleTextCount] = useState(5);

  const handleShowMore = () => {
    setVisibleTextCount(visibleTextCount + 1);
  };

  useEffect( () => {
    if (window.innerWidth < 768) {
      setVisibleTextCount(2);
    }
  }, []);
  return (
    <>
      <Accordion className="homepageSeoAcr">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Step Right Up to Sweeplay!</Accordion.Header>
          <Accordion.Body>
            <div className="homeElementBlock stepRightUp">
              <img
                className="blockBg desktop"
                alt=""
                src={
                  require("assets/sweeplay/images/banners/step-up-banner.png")
                    
                }
              />
              <img
                className="blockBg tab"
                alt=""
                src={
                  require("assets/sweeplay/images/banners/step-up-banner-tab.png")
                    
                }
              />
              <div className="homeBlockContent">
                <h1 className="sweepplay-title">Step Right Up to Sweeplay!</h1>

                {stepUpDesc.slice(0, visibleTextCount).map((desc, index) => (
                  <p key={index} className="sweepplay-paragraph">
                    {desc.paragraph}
                  </p>
                ))}
                {visibleTextCount < stepUpDesc.length && (
                  <div className="loadMoreArea">
                    <Link
                      className="loadMoreBtn slide-bottom"
                      onClick={handleShowMore}
                    >
                      Load More
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke="#CCCCCC"
                          stroke-width="0.048"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 14.5C11.9015 14.5005 11.8038 14.4813 11.7128 14.4435C11.6218 14.4057 11.5392 14.3501 11.47 14.28L8 10.78C7.90861 10.6391 7.86719 10.4715 7.88238 10.3042C7.89756 10.1369 7.96848 9.97954 8.08376 9.85735C8.19904 9.73515 8.352 9.65519 8.51814 9.63029C8.68428 9.6054 8.85396 9.63699 9 9.72003L12 12.72L15 9.72003C15.146 9.63699 15.3157 9.6054 15.4819 9.63029C15.648 9.65519 15.801 9.73515 15.9162 9.85735C16.0315 9.97954 16.1024 10.1369 16.1176 10.3042C16.1328 10.4715 16.0914 10.6391 16 10.78L12.5 14.28C12.3675 14.4144 12.1886 14.4931 12 14.5Z"
                            fill="#ffffff"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
