import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Redirect, Route, Link } from "react-router-dom";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import Translator, { __ } from "services/Translator";
import api from "services/api";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const GameItem = ({ game, isAuthenticated, props }) => {
  const [visible, setVisible] = useState(true);
  const [processed, setProcessed] = useState(false);
  const loaderImage =
    require("assets/sweeplay/images/games/game-loader.gif");
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(loaderImage);

  const defaultImage =
    require("assets/sweeplay/images/games/default.png");
  const params = useParams();

  const gameCategory = params.category ? params.category : "all";

  const hideNoImageFoundGames = () => {
    // setVisible(false);
    setGameSnapshotUrl(defaultImage);
  };

  const loadGameImage = () => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      setProcessed(true);
    }
  };

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      console.log(img.width);
      var width = img.width;
      var height = width * 0.75;
      img.height = height;
      setTimeout(() => {
        //Sometimes the width is read wrong the first time
        //This is the fallback to fix it
        var width = img.width;
        var height = width * 0.75;
        img.height = height;
      }, 1000);
      setProcessed(true);
    }
  };

  useEffect(() => {
    // setProcessed(false);
    // setVisible(true);
    // setGameSnapshotUrl(loaderImage);
    // console.log("Initiate", true);
    // alert("initiate");
    setVisible(true);
    setGameSnapshotUrl(loaderImage);
    setProcessed(false);
  }, [game.id]);

  useEffect(() => {
    console.log("games", game);
  }, []);
  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  const [popOver, setPopOver] = useState(false);

  return (
    <>
      <div className="gameItemBlock">
        <div className="game_thumb">
          <Link
            hrefLang={Translator.getSelectedLanguage().code}
            to={"/play/" + game.slug}
          >
            <img
              src={gameSnapshotUrl}
              onError={hideNoImageFoundGames}
              onLoad={fixImageRatio}
              alt={game.game_name ?? ""}
            />
            {/* <div className="provider_name">{game.provider_id}</div> */}
          </Link>
          <div class="game_overlay">
            {!!game.rtp && (
              <div className="rtp_info">
                <div className="left rtp_text">
                  <>
                    RTP: <span>{game.rtp}%</span>{" "}
                  </>
                </div>
                <div className="right">
                  {/* <OverlayTrigger
                    trigger="hover"
                    placement="top"
                    overlay={
                      <Popover data-bs-theme="dark" id="popover-basic">
                        <Popover.Header as="h3">
                          {game.game_name}
                        </Popover.Header>
                        <Popover.Body>{game.seo_content}</Popover.Body>
                      </Popover>
                    }
                  >
                    <svg
                      fill="#262932"
                      width="24px"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M960 0c530.193 0 960 429.807 960 960s-429.807 960-960 960S0 1490.193 0 960 429.807 0 960 0Zm223.797 707.147c-28.531-29.561-67.826-39.944-109.227-39.455-55.225.657-114.197 20.664-156.38 40.315-100.942 47.024-178.395 130.295-242.903 219.312-11.616 16.025-17.678 34.946 2.76 49.697 17.428 12.58 29.978 1.324 40.49-9.897l.69-.74c.801-.862 1.591-1.72 2.37-2.565 11.795-12.772 23.194-25.999 34.593-39.237l2.85-3.31 2.851-3.308c34.231-39.687 69.056-78.805 115.144-105.345 27.4-15.778 47.142 8.591 42.912 35.963-2.535 16.413-11.165 31.874-17.2 47.744-21.44 56.363-43.197 112.607-64.862 168.888-23.74 61.7-47.405 123.425-70.426 185.398l-2 5.38-1.998 5.375c-20.31 54.64-40.319 108.872-53.554 165.896-10.575 45.592-24.811 100.906-4.357 145.697 11.781 25.8 36.77 43.532 64.567 47.566 37.912 5.504 78.906 6.133 116.003-2.308 19.216-4.368 38.12-10.07 56.57-17.005 56.646-21.298 108.226-54.146 154.681-92.755 47.26-39.384 88.919-85.972 126.906-134.292 12.21-15.53 27.004-32.703 31.163-52.596 3.908-18.657-12.746-45.302-34.326-34.473-11.395 5.718-19.929 19.867-28.231 29.27-10.42 11.798-21.044 23.423-31.786 34.92-21.488 22.987-43.513 45.463-65.634 67.831-13.54 13.692-30.37 25.263-47.662 33.763-21.59 10.609-38.785-1.157-36.448-25.064 2.144-21.954 7.515-44.145 15.046-64.926 30.306-83.675 61.19-167.135 91.834-250.686 19.157-52.214 38.217-104.461 56.999-156.816 17.554-48.928 32.514-97.463 38.834-149.3 4.357-35.71-4.9-72.647-30.269-98.937Zm63.72-401.498c-91.342-35.538-200.232 25.112-218.574 121.757-13.25 69.784 13.336 131.23 67.998 157.155 105.765 50.16 232.284-29.954 232.29-147.084.005-64.997-28.612-111.165-81.715-131.828Z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </OverlayTrigger> */}
                </div>
              </div>
            )}
            <h3 class="game_name">{game.game_name}</h3>
            <div class="game_action">
              {!isAuthenticated && (
                <Link
                  onClick={() => {
                    // Swal.fire({
                    //   className: "dark",
                    //   title: __("Warning"),
                    //   text: __("You need to login to play games"),
                    //   timer: 70000, // Set the time duration in milliseconds
                    //   showConfirmButton: true, // Hide the "OK" button
                    //   icon: "warning",
                    //   customClass: {
                    //     popup: "dark-theme-popup",
                    //     title: "dark-theme-title",
                    //     content: "dark-theme-content",
                    //     confirmButton: "dark-theme-confirm-button",
                    //   },
                    // });
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  class="game_play_icon"
                >
                  <i class="fas fa-play"></i>
                  <span>{__("Sign Up")}</span>
                </Link>
              )}
              {isAuthenticated && (
                <Link
                  class="game_play_icon"
                  to={"/play/" + game.slug + "/real"}
                >
                  <i class="fas fa-play"></i>
                  <span>{__("Play")}</span>
                </Link>
              )}
            </div>
            <div className="game_info">
              <div className="left">
                <h4 class="game_provider_name">{game.provider?.name}</h4>
              </div>
              <div className="right">
                {!isAuthenticated && (
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="favorite_icon"
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2081_1449)">
                        <path
                          d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                          fill="#4698ED"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2081_1449">
                          <rect width="20" height="17.2" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                )}
                {isAuthenticated && (
                  <Link onClick={toggleFavoriteGame} className="favorite_icon">
                    {isFavorite ? (
                      <>
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2081_3472)">
                            <path
                              d="M14.6 0C12.5 0 10.8 1.3 10 2C9.2 1.3 7.4 0 5.4 0C2.4 0 0 2.4 0 5.4C0 7.2 1.7 8.9 1.8 9L10 17.2L18.2 9C18.3 8.9 20 7.2 20 5.4C20 2.4 17.6 0 14.6 0Z"
                              fill="#4698ED"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2081_3472">
                              <rect width="20" height="17.2" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2081_1449)">
                            <path
                              d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                              fill="#4698ED"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2081_1449">
                              <rect width="20" height="17.2" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    )}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameItem;
