import React, { useState, useEffect } from "react";
import api from "../services/api";

export default function LoyaltyProgram(props) {
  const levelsData = [
    {
      name: "Explorer",
      icon: require("assets/sweeplay/images/loyaltyProgram/l1-explorer.svg").default
        ,
      welcomeReward: "-",
      weeklyCoinBoost: "0%",
      weeklyRoughRide: "0%",
      vipAccess: "No",
      gcRequired: "-",
    },
    {
      name: "Adventurer",
      icon: require("assets/sweeplay/images/loyaltyProgram/l2-adventurer.svg").default
        ,
      welcomeReward: "-",
      weeklyCoinBoost: "25%",
      weeklyRoughRide: "2%",
      vipAccess: "No",
      gcRequired: "500,000/month",
    },
    {
      name: "Pioneer",
      icon: require("assets/sweeplay/images/loyaltyProgram/l3-pioneer.svg").default
        ,
      welcomeReward: "-",
      weeklyCoinBoost: "30%",
      weeklyRoughRide: "4%",
      vipAccess: "No",
      gcRequired: "3,000,000/month",
    },
    {
      name: "Voyager",
      icon: require("assets/sweeplay/images/loyaltyProgram/l4-voyager.svg").default
        ,
      welcomeReward: "-",
      weeklyCoinBoost: "35%",
      weeklyRoughRide: "6%",
      vipAccess: "No",
      gcRequired: "15,000,000/month",
    },
    {
      name: "Trailblazer",
      icon: require("assets/sweeplay/images/loyaltyProgram/l5-trailblazer.svg").default
        ,
      welcomeReward: "GC 100,000 + Free SC 50",
      weeklyCoinBoost: "40%",
      weeklyRoughRide: "8%",
      vipAccess: "No",
      gcRequired: "60,000,000/month",
    },
    {
      name: "Navigator",
      icon: require("assets/sweeplay/images/loyaltyProgram/l6-navigator.svg").default
        ,
      welcomeReward: "GC 500,000 + Free SC 250",
      weeklyCoinBoost: "50%",
      weeklyRoughRide: "12%",
      vipAccess: "Yes",
      gcRequired: "500,000,000/year",
      highlight: true,
    },
    {
      name: "Pathfinder",
      icon: require("assets/sweeplay/images/loyaltyProgram/l7-pathfinder.svg").default
        ,
      welcomeReward: "GC 2,000,000 + Free SC 1,000",
      weeklyCoinBoost: "75%",
      weeklyRoughRide: "20%",
      vipAccess: "Yes",
      gcRequired: "2,500,000,000/year",
      highlight: true,
    },
    {
      name: "Legend",
      icon: require("assets/sweeplay/images/loyaltyProgram/l8-legend.svg").default
        ,
      welcomeReward: "GC 5,000,000 + Free SC 2,500",
      weeklyCoinBoost: "100%",
      weeklyRoughRide: "25%",
      vipAccess: "Yes",
      gcRequired: "12,500,000,000/year",
      highlight: true,
    },
  ];

  const vipBenefits = [
    {
      category: "VIP Host",
      benefits: [
        "Around-the-clock personal service",
        "Custom rewards made just for you",
        "A surprise-filled Welcome Package upon joining",
      ],
    },
    {
      category: "VIP Exclusive Promotions",
      benefits: [
        "Top-tier Coin Boost Deals, promotions, and offers",
        "Special treats for your milestones",
        "Compete in Wall of Fame weekly tournaments",
      ],
    },
    {
      category: "VIP Gifts & Events",
      benefits: [
        "Handpicked gifts tailored to your tastes",
        "Exclusive invitations to red carpet VIP events",
        "Unique discounts and gift vouchers just for you",
      ],
    },
  ];
  return (
    <>
      <div className="container-xxl">
        <div className="loyaltyProgram">
          <h1 className="pageHeading">VIP & Loyalty Club</h1>
          {props.isAuthenticated && (
            <div className="loyalty-club-container">
              <div className="loyalty-level">
                <img
                  src={
                    require("assets/sweeplay/images/loyaltyProgram/l1-explorer.svg").default
                      
                  }
                  alt="Explorer"
                  className="loyalty-icon"
                />
                <div>
                  <h2>Explorer</h2>
                  <p>Your Loyalty Level</p>
                </div>
              </div>
              <div className="loyalty-progress">
                <div className="progress-bar-container">
                  <div className="progress-bar" style={{ width: "0%" }}></div>
                </div>
                <div className="progress-labels">
                  <div className="progress-label">
                    <img
                      src={
                        require("assets/sweeplay/images/loyaltyProgram/l1-explorer.svg").default
                          
                      }
                      alt="Explorer"
                    />
                    <span>Explorer</span>
                  </div>
                  <div className="progress-label">
                    <img
                      src={
                        require("assets/sweeplay/images/loyaltyProgram/l2-adventurer.svg").default
                          
                      }
                      alt="Adventurer"
                    />
                    <span>Adventurer</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="loyaltyHeadings">
            <h1>Sweeplay Loyalty Club</h1>
            <h2>Open the Doors to More Rewards</h2>
            <p>
              Welcome to Sweeplay Loyalty Club, where your loyalty is rewarded
              with exclusive surprises and treats while you play your favorite
              games.
            </p>
          </div>
          <div className="loyalty-rewards-container">
            <div className="rewards-grid">
              <div className="reward-card">
                <img
                  src={
                    require("assets/sweeplay/images/loyaltyProgram/welcome-rewards.png")
                      
                  }
                  alt="Welcome Rewards"
                />
                <h3>Unlock Rewards</h3>
                <p>
                  Join us and snag a unique Welcome Reward at Platinum level,
                  with even more surprises waiting as you ascend through the
                  ranks!
                </p>
              </div>
              <div className="reward-card">
                <img
                  src={
                    require("assets/sweeplay/images/loyaltyProgram/weekly-coin-v3.png")
                      
                  }
                  alt="Weekly Coin Boost Deal"
                />
                <h3>Weekly Coin Boost Bonanza</h3>
                <p>
                  Get up to 100% extra coins with every purchase and feel the
                  thrill of boosted gameplay as you unlock new levels!
                </p>
              </div>
              <div className="reward-card">
                <img
                  src={
                    require("assets/sweeplay/images/loyaltyProgram/exclusive-v2.png")
                      
                  }
                  alt="Exclusive Promotions"
                />
                <h3>Special Invites</h3>
                <p>
                  Enjoy golden ticket access to exclusive tournaments, unique
                  promotions, and thrilling weekly competitions.
                </p>
              </div>
              <div className="reward-card">
                <img
                  src={
                    require("assets/sweeplay/images/loyaltyProgram/vip-club-v2.png")
                      
                  }
                  alt="VIP Club"
                />
                <h3>Enter the VIP Elite</h3>
                <p>
                  Climb to the top tiers and experience the royal treatment with
                  an invitation to our prestigious VIP Club!
                </p>
              </div>
            </div>
          </div>
          <div className="loyalty-steps-container">
            <div className="loyaltyHeadings">
              <h2>Joining the SweeplayLoyalty Club is as easy as 1, 2, 3...</h2>
            </div>
            <div className="steps-grid">
              <div className="step-card">
                {/* <img src={step1Icon} alt="Step 1" /> */}
                <div className="stepsCount">1</div>
                <p>
                  Join the Sweeplay Loyalty Club in the ‘Iron’ level and as you
                  enjoy Gold Coin games you'll reach the higher levels and
                  awesome rewards.
                </p>
              </div>
              <div className="step-card">
                <div className="stepsCount">2</div>
                <p>
                  As you progress through the levels, you'll get to unlock
                  bigger and better rewards.
                </p>
              </div>
              <div className="step-card">
                <div className="stepsCount">3</div>
                <p>
                  Keep your status all month long, plus 2 consecutive months
                  afterwards to enjoy your rewards for even longer.
                </p>
              </div>
            </div>
          </div>
          <div className="loyaltyHeadings">
            <h1>8 Levels to Climb</h1>

            <p>
              Upgrade through the levels and reach the most prestigious Ruby,
              Diamond or Black Diamond status to earn Weekly Coin Boost Deals
              and other exciting loyalty rewards. Reaching these levels means
              you can keep your status for a whole year and enjoy access to the
              number 1 VIP Club on the market.
            </p>
          </div>
          <div className="levels-grid">
            {levelsData.map((level, index) => (
              <div
                key={index}
                className={`level-card ${level.highlight ? "highlight" : ""}`}
              >
                <div className="level-icon">
                  <img src={level.icon} alt={`${level.name} icon`} />
                </div>
                <div className="level-details">
                  <h3>{level.name}</h3>
                  <p>Welcome reward: {level.welcomeReward}</p>
                  <p>Weekly Coin Boost Deal: {level.weeklyCoinBoost}</p>
                  <p>Weekly Rough Ride Reward: {level.weeklyRoughRide}</p>
                  <p>VIP Club access: {level.vipAccess}</p>
                  <p>GC required to play: {level.gcRequired}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="loyaltyHeadings">
            <h1>The VIP Club</h1>

            <p>Experience unparalleled perks and extraordinary rewards!</p>
          </div>
          <div className="vip-club">
            {vipBenefits.map((section, index) => (
              <div key={index} className="vip-section">
                <h3>{section.category}</h3>
                <ul>
                  {section.benefits.map((benefit, i) => (
                    <li key={i}>
                      <div role="img" className="checkmark" aria-label="check">
                        <img
                          src={
                            require("assets/sweeplay/images/loyaltyProgram/check-mark.svg").default
                              
                          }
                          alt="✔︎"
                        />
                      </div>{" "}
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
